import React, { Children } from "react";
import { Dialog } from "primereact/dialog";

export default function CommonModal({ onHide = () => {}, children, ...props }) {
  return (
    <React.Fragment>
      <Dialog header={props.header} visible={props.visible} onHide={onHide}>
        {children}
      </Dialog>
    </React.Fragment>
  );
}
