import React, { useState } from "react";
import Search from "../components/Search";
import './Settings.scss';
const Settings = () => {
    return (
        <div className="Settings__main-container">
            {/* <div className="Settings__search-container">
            <Search/>
            </div> */}
            <div className="Settings__card-container">
                Settings
            </div>
            <div className="Settings__table-container">
            
            </div>
        </div>
    )
}
 export default Settings;