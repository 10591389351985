import React, { useEffect, useState } from "react";
import AllLogistic from "./allLogistic";

export default function ActionLogistic({
  api,
  refresh,
  dataKey,
  selectable,
  optionsView,
  searchOption,
  isButtonEnabledLeft,
  handleAction = () => {},
  currentPageHeader = "Arrived",
  handSecoundaryleClick,
  ...props
}) {
  const [checkBox, setCheckBox] = useState([]);

  const handleClick = (exist, event) => {
    handleAction(event, checkBox);
  };

  useEffect(() => {
    console.log(refresh);
  }, [refresh]);

  return (
    <AllLogistic
      refresh={refresh}
      api={api}
      dataKey={dataKey}
      selectable={selectable}
      searchOption={searchOption}
      checkBox={checkBox}
      optionsView={optionsView}
      setCheckBox={setCheckBox}
      handleClick={handleClick}
      secoundaryButton={
        handSecoundaryleClick && [
          {
            type: "primarySuccessButton",
            label: "Missed",
            icon: "pi pi-user",
            class: "mt-2 w-full flex pr-3 -ml-3",
            disabled: checkBox?.length === 0,
            onClick: () => {
              handSecoundaryleClick(checkBox);
            },
          },
        ]
      }
      columnArray={props?.columnArray}
      isButtonEnabledLeft={isButtonEnabledLeft}
      currentPageHeader={currentPageHeader}
    />
  );
}
