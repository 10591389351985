export const financial = {
  series: [0, 0],
  options: {
    chart: {
      type: "donut",
    },
    labels: ["Paid", "Unpaid"],
    colors: ["#28C76F", "#A9E9C5"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
  },
};
