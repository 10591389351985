import React from "react";
import ButtonComponent from "../commonComponents/buttonComponet";
import { getButtonClass } from "../utils/utils";

export default function ButtonPrimaryComponent(props) {
    
    return (
        <div className="button-primary-container">
            <ButtonComponent 
            className={`w-full py-2_5 justify-content-center ${getButtonClass(props.type)}`}
                {...props}
            />
        </div>
    );  
}