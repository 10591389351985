import moment from "moment";
import { pattern } from "./utils";

export const patientFormRender = [
  {
    type: "text",
    name: "first_name",
    required: true,
    label: "First Name",
    infoText: "",
    fieldLabel: "first_name",
    autoFocus: true,
    rules: {
      required: "First Name is required",
      pattern: {
        value: pattern("name"),
        message: "*First Name Should only contain letters or number",
      },
      validate: (value) => value.trim() !== "" || "Whitespace is not allowed",
    },
  },
  {
    type: "text",
    name: "last_name",
    required: true,
    label: "Last Name",
    infoText: "",
    fieldLabel: "last_name",
    rules: {
      required: "Last Name is required",
      pattern: {
        value: pattern("name"),
        message: "*Last Name Should only contain letters or number",
      },
      validate: (value) => value.trim() !== "" || "Whitespace is not allowed",
    },
  },
  {
    item: [
      {
        type: "dropdown",
        name: "gender",
        options: [
          { label: "Male", value: "male" },
          { label: "Female", value: "female" },
        ],
        required: true,
        label: "Gender",
        fieldLabel: "gender",
        rules: {
          required: "Gender is required",
        },
      },
      {
        type: "calendar",
        name: "dob",
        label: "Date of Birth",
        required: true,
        fieldLabel: "dob",
        dataType: "date",
        maxDate: moment().subtract(1, "days")?._d,
        rules: {
          required: "Date of Birth is required",
        },
      },
    ],
  },
  {
    type: "text",
    name: "address",
    label: "Street Address",
    required: true,
    fieldLabel: "address",
    rules: {
      required: "Address is required",
      pattern: {
        value: pattern("address"),
        message: "*Special characters like , and /  are  accept",
      },
      validate: (value) => value.trim() !== "" || "Whitespace is not allowed",
    },
  },
  {
    item: [
      {
        type: "text",
        name: "city",
        label: "City",
        required: true,
        fieldLabel: "city",
        rules: {
          required: "City is required",
          pattern: {
            value: pattern("cities"),
            message: "*City Should only contain letters",
          },
          validate: (value) =>
            value.trim() !== "" || "Whitespace is not allowed",
        },
      },
      {
        type: "text",
        name: "district",
        label: "District",
        fieldLabel: "district",
        required: true,
        rules: {
          required: "District is required",
          pattern: {
            value: pattern("cities"),
            message: "*District Should only contain letters",
          },
          validate: (value) =>
            value.trim() !== "" || "Whitespace is not allowed",
        },
      },
    ],
  },
  {
    item: [
      {
        type: "text",
        name: "state",
        label: "State",
        required: true,
        fieldLabel: "state",
        rules: {
          required: "State is required",
          pattern: {
            value: pattern("cities"),
            message: "*State Should only contain letters",
          },
          validate: (value) =>
            value.trim() !== "" || "Whitespace is not allowed",
        },
      },
      {
        type: "text",
        name: "pincode",
        label: "Pincode",
        required: true,
        fieldLabel: "pincode",
        rules: {
          required: "Pincode is required",
          pattern: {
            value: pattern("pinCode"),
            message: "*Enter Valid pin code",
          },
          maxLength: {
            value: 6,
            message: "*Pin Code Should be in 6 Digit",
          },
          minLength: {
            value: 6,
            message: "*Pin Code Should be in 6 Digit",
          },
        },
      },
    ],
  },
  {
    type: "text",
    name: "phone_no",
    label: "Phone Number",
    fieldLabel: "phone_no",
    rules: {
      pattern: {
        value: pattern("phoneNo"),
        message: "*Enter Valid Phone Number",
      },
      minLength: {
        value: 10,
        message: "*Min 10 digit have to added",
      },
      maxLength: {
        value: 10,
        message: "*Max 10 digit only allowed",
      },
    },
  },
  {
    type: "text",
    name: "aadhar_no",
    label: "Aadhaar Number",
    fieldLabel: "aadhar_no",
    infoText: "",
    rules: {
      pattern: {
        value: pattern("aadharNo"),
        message: "*Enter Valid Adhaar Number",
      },
      maxLength: {
        value: 12,
        message: "*Only 12 digit are allowed",
      },
      minLength: {
        value: 12,
        message: "*Min 12 digit have to added",
      },
    },
  },
  {
    type: "text",
    name: "health_insurance_card",
    label: "Aarogyasri Number",
    infoText: "",
    fieldLabel: "health_insurance_card",
    rules: {
      pattern: {
        value: pattern("aarogyasri"),
        message: "*Please Enter Valid Aarogyasri Number",
      },
      maxLength: {
        value: 11,
        message: "*Only 11 digit are allowed",
      },
      minLength: {
        value: 11,
        message: "*Min 11 digit have to added",
      },
    },
  },
  {
    type: "text",
    name: "ration_card_no",
    label: "Ration Card Number",
    infoText: "",
    fieldLabel: "ration_card_no",
    rules: {
      pattern: {
        value: pattern("rationCardNo"),
        message: "*Please Enter Valid Ration Card Number",
      },
      maxLength: {
        value: 10,
        message: "*Only 10 digit are allowed",
      },
      minLength: {
        value: 10,
        message: "*Min 10 digit have to added",
      },
    },
  },
];

export const nonRequirePatientListField = [
  "patient_id",
  "created_at",
  "updated_at",
  "created_by",
  "updated_by",
  "profile_picture",
  "first_name",
  "last_name",
  "ration_card_no",
  "health_insurance_card",
  "address",
  "district",
  "state",
  "city",
  "pincode",
  "is_active",
  "is_deleted",
];

export const nonRequireMedicalListField = [
  "medical_prescription_id",
  "created_at",
  "created_by",
  "updated_at",
  "updated_by",
  "patient_visit_id",
];

export const nonRequirePatienttransportationdetails = [
  "patient_visit_id",
  "status",
  "no_of_followup_calls",
  "transportation_date",
  "status",
  "no_of_followup_calls",
  "created_at",
  "updated_at",
  "created_by",
  "updated_by",
  "patient_transportation_details_id",
];

export const nonRequireVisitListField = [
  "attented_by",
  "created_at",
  "updated_at",
  "created_by",
  "updated_by",
  "camp_details_id",
  "patient_id",
  "patient_visit_id",
  "profile_picture",
  "first_name",
  "last_name",
  "ration_card_no",
  "health_insurance_card",
  "address",
  "district",
  "state",
  "city",
  "pincode",
];

export const nonRequireLogisticsField = [
  "created_at",
  "created_by",
  "patient_id",
  "no_of_followup_calls",
  "patient_transportation_details_id",
  "patient_visit_id",
  "surgery_recommended" ,
  "updated_at",
  "updated_by"
];

export const addVisits = [
  {
    type: "calendar",
    name: "date",
    label: "Date",
    fieldLabel: "date",
    dataType: "date",
    minDate: new Date(),
    className: "calender-std-height",
    rules: {
      required: "Date is required",
    },
  },
  {
    type: "dropdown",
    name: "purpose",
    options: [],
    label: "Purpose",
    fieldLabel: "purpose",
    rules: {
      required: "Purpose is required",
    },
  },
  {
    type: "dropdown",
    name: "examined_by",
    options: [],
    label: "Examined By",
    fieldLabel: "examined_by",
    rules: {
      required: "Examined By is required",
    },
  },
];

export const addPayment = [
  {
    type: "calendar",
    name: "payment_date",
    label: "Date",
    fieldLabel: "payment_date",
    dataType: "date",
    rules: {
      required: "Date is required",
    },
  },
  {
    type: "dropdown",
    name: "collectable_payment_description_id",
    options: [],
    label: "Invoice",
    fieldLabel: "collectable_payment_description_id",
    rules: {
      required: "Invoice is required",
    },
  },
  {
    type: "currency",
    name: "total_collectable_amount",
    disabled: true,
    label: "Invoice Amount",
    fieldLabel: "total_collectable_amount",
  },
  {
    type: "currency",
    name: "total_paid_amount",
    disabled: true,
    label: "Paid Amount",
    fieldLabel: "total_paid_amount",
  },
  {
    type: "currency",
    name: "total_balance_amount",
    disabled: true,
    label: "Balance Amount",
    fieldLabel: "total_balance_amount",
  },
  {
    type: "currency",
    name: "amount_paid",
    options: [],
    required: true,
    label: "Payment Amount",
    placeholder:"Enter value",
    fieldLabel: "amount_paid",
    rules: {
      required: "Payment amount is required",
    },
  },
];

export const visualHeading = ["TYPE", "SPH", "NECYLAR", "AXIS", "VISION"];

export const visualTable = [
  {
    "Vision Type": {
      name: "Distance",
    },
    Sph: {
      name: "Sph",
    },
    NeCylar: {
      name: "NeCylar",
    },
    Axis: {
      name: "Axis",
    },
    Vision: {
      name: "Vision",
    },
  },
  {
    "Vision Type": {
      name: "Near",
    },
    Sph: {
      name: "Sph",
    },
    NeCylar: {
      name: "NeCylar",
    },
    Axis: {
      name: "Axis",
    },
    Vision: {
      name: "Vision",
    },
  },
];

export const userFormRender = [
  {
    type: "text",
    name: "first_name",
    label: "First Name",
    fieldLabel: "first_name",
    autoFocus: true,
  },
  {
    type: "text",
    name: "last_name",
    label: "Last Name",
    fieldLabel: "last_name",
  },
  {
    type: "text",
    name: "user_name",
    label: "Username",
    fieldLabel: "user_name",
  },
  {
    type: "text",
    name: "email",
    label: "Email",
    fieldLabel: "email",
    rules: {
      pattern: {
        value: pattern("email"),
        message: "*Enter Valid Email",
      }
    },
  },
  {
    type: "password",
    name: "password",
    label: "Password",
    fieldLabel: "password",
    rules: {
      minLength: {
        value: 8,
        message: "*Minimum 8 length",
      },
    },
  },
  {
    type: "text",
    name: "phone_no",
    label: "Phone Number",
    fieldLabel: "phone_no",
    rules: {
      pattern: {
        value: pattern("phoneNo"),
        message: "*Enter Valid Phone Number",
      },
      minLength: {
        value: 10,
        message: "*Min 10 digit have to added",
      },
      maxLength: {
        value: 10,
        message: "*Max 10 digit only allowed",
      },
    },
  },
  {
    type: "text",
    name: "address",
    label: "Street Address",
    fieldLabel: "address",
    rules: {
      pattern: {
        value: pattern("address"),
        message: "*Special characters like , and /  are  accept",
      },
    },
  },
  {
    type: "dropdown",
    name: "user_type",
    options: [],
    required: true,
    label: "User Type",
    fieldLabel: "user_type",
    rules: {
      required: "User Type is required",
    },
  },
  {
    item: [
      {
        type: "text",
        name: "city",
        label: "City",
        fieldLabel: "city",
        rules: {
          pattern: {
            value: pattern("cities"),
            message: "*City Should only contain letters",
          },
          validate: (value) =>
            value?.trim() !== "" || "Whitespace is not allowed",
        },
      },
      {
        type: "text",
        name: "district",
        label: "District",
        fieldLabel: "district",
        rules: {
          pattern: {
            value: pattern("cities"),
            message: "*District Should only contain letters",
          },
          validate: (value) =>
            value?.trim() !== "" || "Whitespace is not allowed",
        },
      },
    ],
  },
  {
    item: [
      {
        type: "text",
        name: "state",
        label: "State",
        fieldLabel: "state",
        rules: {
          pattern: {
            value: pattern("cities"),
            message: "*State Should only contain letters",
          },
          validate: (value) =>
            value?.trim() !== "" || "Whitespace is not allowed",
        },
      },
      {
        type: "text",
        name: "pincode",
        label: "Pincode",
        fieldLabel: "pincode",
        rules: {
          pattern: {
            value: pattern("pinCode"),
            message: "*Enter Valid pin code",
          },
          maxLength: {
            value: 6,
            message: "*Pin Code Should be in 6 Digit",
          },
          minLength: {
            value: 6,
            message: "*Pin Code Should be in 6 Digit",
          },
        },
      },
    ],
  },
];

export const assignRoleFormRender = [
  {
    type: "dropdown",
    name: "camp",
    options: [],
    label: "Camp",
    fieldLabel: "camp",
  },
  {
    type: "dropdown",
    name: "role",
    options: [],
    label: "Role",
    fieldLabel: "role",
  },
];


export const rolesAndPermissionFormRender = [
  {
    type: "text",
    name: "permission_name",
    label: "Permission Name",
    fieldLabel: "permission_name",
  },
  {
    type: "password",
    name: "permission_ID",
    label: "Permission ID",
    fieldLabel: "permission_ID",
  },
  {
    type: "dropdown",
    name: "roles",
    options: [],
    label: "Roles",
    fieldLabel: "roles",
  },
];

export const addRoleFormRender = [
  {
    type: "text",
    name: "role_name",
    label: "Role Name",
    fieldLabel: "role_name",
  },
  {
    type: "text",
    name: "role_ID",
    label: "Role ID",
    fieldLabel: "role_ID",
  },
  {
    type: "text",
    name: "status",
    label: "Status",
    fieldLabel: "status",
  },
];

export const campFormRender = [
  {
    type: "text",
    name: "camp_name",
    label: "Camp Name",
    fieldLabel: "camp_name",
    autoFocus: true,
  },
  {
    type: "text",
    name: "camp_id",
    label: "Camp ID",
    fieldLabel: "camp_id",
  },
  {
    type: "text",
    name: "organization_id",
    label: "Organization ID",
    fieldLabel: "organization_id",
  },
  {
    type: "calendar",
    name: "date_range",
    label: "Date Range",
    fieldLabel: "date_range",
    dataType: "date",
  },
  {
    type: "dropdown",
    name: "frequency",
    options: [],
    label: "Frequency",
    fieldLabel: "frequency",
  },
  {
    type: "dropdown",
    name: "type",
    options: [],
    label: "Type",
    fieldLabel: "type",
  },
  {
    type: "text",
    name: "address",
    label: "Street Address",
    fieldLabel: "address",
    rules: {
      pattern: {
        value: pattern("address"),
        message: "*Special characters like , and /  are  accept",
      },
    },
  },
  {
    item: [
      {
        type: "text",
        name: "city",
        label: "City",
        fieldLabel: "city",
        rules: {
          pattern: {
            value: pattern("cities"),
            message: "*City Should only contain letters",
          },
          validate: (value) =>
            value?.trim() !== "" || "Whitespace is not allowed",
        },
      },
      {
        type: "text",
        name: "district",
        label: "District",
        fieldLabel: "district",
        rules: {
          pattern: {
            value: pattern("cities"),
            message: "*District Should only contain letters",
          },
          validate: (value) =>
            value?.trim() !== "" || "Whitespace is not allowed",
        },
      },
    ],
  },
  {
    item: [
      {
        type: "text",
        name: "state",
        label: "State",
        fieldLabel: "state",
        rules: {
          pattern: {
            value: pattern("cities"),
            message: "*State Should only contain letters",
          },
          validate: (value) =>
            value?.trim() !== "" || "Whitespace is not allowed",
        },
      },
      {
        type: "text",
        name: "pincode",
        label: "Pincode",
        fieldLabel: "pincode",
        rules: {
          pattern: {
            value: pattern("pinCode"),
            message: "*Enter Valid pin code",
          },
          maxLength: {
            value: 6,
            message: "*Pin Code Should be in 6 Digit",
          },
          minLength: {
            value: 6,
            message: "*Pin Code Should be in 6 Digit",
          },
        },
      },
    ],
  },
  {
    type: "text",
    name: "contact_name",
    label: "Contact Name",
    fieldLabel: "contact_name",
  },
  {
    type: "text",
    name: "contant_phone_number",
    label: "Contact Phone Number",
    fieldLabel: "contant_phone_number",
    rules: {
      pattern: {
        value: pattern("contant_phone_number"),
        message: "*Enter Valid Phone Number",
      },
      minLength: {
        value: 10,
        message: "*Min 10 digit have to added",
      },
      maxLength: {
        value: 10,
        message: "*Max 10 digit only allowed",
      },
    },
  },
];
 
export const organizationFormRender = [
  {
    type: "text",
    name: "organization_name",
    label: "Organization Name",
    fieldLabel: "organization_name",
    autoFocus: true,
  },
  {
    type: "text",
    name: "organization_id",
    label: "Organization ID",
    fieldLabel: "organization_id",
  },
  {
    type: "text",
    name: "description",
    label: "Description",
    fieldLabel: "description",
  },
  {
    type: "text",
    name: "address",
    label: "Street Address",
    fieldLabel: "address",
    rules: {
      pattern: {
        value: pattern("address"),
        message: "*Special characters like , and /  are  accept",
      },
    },
  },
  {
    item: [
      {
        type: "text",
        name: "city",
        label: "City",
        fieldLabel: "city",
        rules: {
          pattern: {
            value: pattern("cities"),
            message: "*City Should only contain letters",
          },
          validate: (value) =>
            value?.trim() !== "" || "Whitespace is not allowed",
        },
      },
      {
        type: "text",
        name: "district",
        label: "District",
        fieldLabel: "district",
        rules: {
          pattern: {
            value: pattern("cities"),
            message: "*District Should only contain letters",
          },
          validate: (value) =>
            value?.trim() !== "" || "Whitespace is not allowed",
        },
      },
    ],
  },
  {
    item: [
      {
        type: "text",
        name: "state",
        label: "State",
        fieldLabel: "state",
        rules: {
          pattern: {
            value: pattern("cities"),
            message: "*State Should only contain letters",
          },
          validate: (value) =>
            value?.trim() !== "" || "Whitespace is not allowed",
        },
      },
      {
        type: "text",
        name: "pincode",
        label: "Pincode",
        fieldLabel: "pincode",
        rules: {
          pattern: {
            value: pattern("pinCode"),
            message: "*Enter Valid pin code",
          },
          maxLength: {
            value: 6,
            message: "*Pin Code Should be in 6 Digit",
          },
          minLength: {
            value: 6,
            message: "*Pin Code Should be in 6 Digit",
          },
        },
      },
    ],
  },
  {
    type: "text",
    name: "contact_name",
    label: "Contact Name",
    fieldLabel: "contact_name",
  },
  {
    type: "text",
    name: "contant_phone_number",
    label: "Contact Phone Number",
    fieldLabel: "contant_phone_number",
    rules: {
      pattern: {
        value: pattern("contant_phone_number"),
        message: "*Enter Valid Phone Number",
      },
      minLength: {
        value: 10,
        message: "*Min 10 digit have to added",
      },
      maxLength: {
        value: 10,
        message: "*Max 10 digit only allowed",
      },
    },
  },
];
