import React, { Suspense, useContext, lazy } from "react";
import logo from "./logo.svg";
import "./App.scss";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "./Icon/icomoon/style.css";
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import Layout from "./screens/Layout";
import Patients from "./screens/patients/Patients";
import Dashboard from "./screens/Dashboard";
import Logistics from "./screens/logistics/Logistics";
import Invoices from "./screens/invoices/Invoices";
import Reports from "./screens/Reports";
import Settings from "./screens/Settings";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Login from "./screens/auth/Login";
import ForgotPassword from "./screens/auth/forgotPassword";
import ResetPassword from "./screens/auth/resetPassword";
import Visits from "./screens/visits/Visits";
import Users from "./screens/admin/users";
import RolesPermission from "./screens/admin/roles&permission";
import Camps from "./screens/admin/campUser";
import Organizations from "./screens/admin/organizations";
import "./assets/styles/common.scss";
import ProtedtedRoute from "./route/ProtedtedRoute";
import ToastComponent from "./commonComponents/toast";

function App() {
  const routes = [
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/patients", element: <Patients /> },
    { path: "/logistics", element: <Logistics /> },
    { path: "/invoices", element: <Invoices /> },
    { path: "/reports", element: <Reports /> },
    { path: "/settings", element: <Settings /> },
    { path: "/visits/:id", element: <Visits /> },
    { path: "/users", element: <Users /> },
  ];
  return (
    <div className="App">
      {/* <Login/> */}
      <Router>
        {/* <Navbar /> */}
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route exact path="/resetpassword" element={<ResetPassword />} />
          <Route element={<Layout />}>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<ProtedtedRoute element={route.element} />}
              />
            ))}
          </Route>
        </Routes>
      </Router>
      <ToastComponent/>
    </div>
  );
}

export default App;
