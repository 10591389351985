import React, { useEffect, useState } from "react";
import AddButtonSection from "../../components/AddButtonSection";
import { dataToNameAndLabel, renderInputHeader,orderObj } from "../../utils/utils";
import { useForm } from "react-hook-form";
import DataGrid from "../../commonComponents/dataGrid/dataGrid";
import { commonApi } from "../../utils/apiService";
import ButtonWithSearch from "../../components/buttonWithSearch";
import { nonRequireMedicalListField} from "../../utils/serviceData";
import { Button } from "primereact/button";
import { showToast } from "../../commonComponents/toast";
export default function MedicalPrescription({patientVisitId}) {
  const {
    control,
    formState: { errors,dirtyFields },
    watch,
    setValue,
    handleSubmit,
    reset,
  } = useForm();
  const headers = {
    "Content-Type": "application/json",
  };
  const [listLoading, setListLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [medicineId, setMedicineId] = useState("");
  const [navigator, setNavigator] = useState({
    page: 1,
    first: 0,
    rows: 10,
    totalRecords: 1,
    sortBy: "medicine_name",
    sortDir: "ASC",
    searchCriteria:{
      medicine_name:""
    }
  });
  const [productList, setProductList] = useState([]);
  const [listValues, setListValues] = useState([]);
  const [edit, setEdit] = useState(false);
  const medicalFields = [
      "medicine_name",
      "dosage",
      "type",
      "frequency",
      "route",
      "period",
    ]

    const itemRenderer = (item, props) => (
      <div className="p-menuitem-content">
        {item.label === "Edit" && (
          <Button
            className="card-button-edit"
            label="Edit"
            onClick={() => {
              const formData = props?.props?.itemProp
              setMedicineId(formData?.medical_prescription_id || null)
              medicalFields.forEach(field =>{
                let value = formData ? formData[field] : '';
                setValue(field, value || '');
              })
              setEdit(true);
            }}
          />
        )}
        {item.label === "Remove" && (
          <Button
            className="card-button-delete"
            label="Delete"
            onClick={() => {
              alert("Delete")
            }}
          />
        )}
      </div>
    );

  const formData = [
    {
      parentType: "wrap",
      wrap: [
        {
          type: "input-group",
          parentType: "wrap-with-flex",
          // header: "Ophthalmic History",
          customClass: "py-3",
          formRender: [
            {
              type: "dropdown",
              name: "medicine_name",
              label: "Name",
              fieldLabel: "medicine_name",
              placeholder: "Select",
              // placeholder: "Type to search",
              // value: watch("surgery_recommended"),
              inputParentClass: "w-full pr-3 div_pb-0",
              options: [
                {
                  label: "Paracetamol",
                  value: "Paracetamol",
                },
                {
                  label: "dart1",
                  value: "dart1",
                },
                {
                  label: "dent2",
                  value: "dent2",
                },
              ],
            },
          ],
        },
        {
          type: "input-group",
          parentType: "wrap-with-flex",
          // header: "Allergies",
          customClass: "py-3",
          formRender: [
            {
              type: "text",
              name: "dosage",
              label: "Dosage",
              fieldLabel: "dosage",
              placeholder: "Type here",
              inputParentClass: "w-full pr-3 div_pb-0",
            },
          ],
        },
        {
          type: "input-group",
          parentType: "wrap-with-flex",
          // header: "Allergies",
          customClass: "py-3",
          formRender: [
            {
              type: "text",
              name: "type",
              label: "Type",
              fieldLabel: "type",
              placeholder: "Type here",
              // placeholder: "Type to search",
              inputParentClass: "w-full pr-3 div_pb-0",
            },
          ],
        },
      ],
    },
    {
      parentType: "wrap",
      wrap: [
        {
          type: "input-group",
          parentType: "wrap-with-flex",
          // header: "Ophthalmic History",
          customClass: "py-3",
          formRender: [
            {
              type: "dropdown",
              name: "frequency",
              label: "Frequency",
              fieldLabel: "frequency",
              placeholder: "Select",
              // placeholder: "Type to search",
              // value: watch("surgery_recommended"),
              inputParentClass: "w-full pr-3 div_pb-0",
              options: [
                {
                  label: "dart1",
                  value: "dart1",
                },
                {
                  label: "dent2",
                  value: "dent2",
                },
              ],
            },
          ],
        },
        {
          type: "input-group",
          parentType: "wrap-with-flex",
          // header: "Allergies",
          customClass: "py-3",
          formRender: [
            {
              type: "dropdown",
              name: "route",
              label: "Route",
              fieldLabel: "route",
              placeholder: "Select",
              // placeholder: "Type to search",
              options: [
                {
                  label: "dart1",
                  value: "dart1",
                },
                {
                  label: "dent2",
                  value: "dent2",
                },
                {
                  label: "Oral",
                  value: "oral",
                },
              ],
              inputParentClass: "w-full pr-3 div_pb-0",
            },
          ],
        },
        {
          type: "input-group",
          parentType: "wrap-with-flex",
          // header: "Allergies",
          customClass: "py-3",
          formRender: [
            {
              type: "text",
              name: "period",
              label: "Period",
              fieldLabel: "period",
              placeholder: "Type here",
              inputParentClass: "w-full pr-3 div_pb-0",
            },
          ],
        },
      ],
    },
  ];

  const onSubmit = async (data) => {
    if(loading)return;
    setLoading(true)
    let medicalPrescription;
    if(!edit){
      medicalPrescription = {
        ...data,
        patient_visit_id:patientVisitId,
        medical_prescription_id:null
      }
    }else if(edit){
      medicalPrescription = {
        ...data,
        patient_visit_id:patientVisitId,
        medical_prescription_id:medicineId
      }
    }
    const action = edit ? 'editMedicine' : 'addMedicine';
    await commonApi(`api/${action}`, medicalPrescription , {
      headers,
    })
    .then((data) => {
      if(data?.data){
        showToast("success", `${edit ? 'Updated' : 'Added'} Medical Prescription`);
        reset({
          dosage:"",
          type:"",
          period:""
        });
        setEdit(false)
        init(navigator);
        setLoading(false);
      }
    })
    .catch((err) => {
       showToast("error", "Something went wrong");
       setLoading(false);
    });
  }

  const submitButtons = [
    {
      type: "primarySuccessButton",
      label: "Save Changes",
      class: "w-11rem pr-3",
      disabled:Object.keys(dirtyFields).length === 0 || loading,
      onClick: handleSubmit(onSubmit),
    },
    {
      type: "secondaryButton",
      label: "Discard",
      class: "w-7rem",
      onClick: () => {
        reset({
          dosage:"",
          type:"",
          period:""
        });
        setEdit(false)
      },
    },
  ];

  const init = async (navigator) => {
    let currentObj = {
      query: {
        searchCriteria: {
          medicine_name:navigator?.searchCriteria?.medicine_name,
          patient_visit_id: patientVisitId 
        },
        page: navigator?.page,
        limit: navigator?.rows,
        sortFields:[[navigator?.sortBy, navigator?.sortDir]]
      },
    };
    await commonApi('api/searchMedicalPrescriptionDetails', currentObj , {
      headers,
    })
      .then((data = []) => {
        if (data?.data?.data) {
          const finalData = data?.data?.data;
          setNavigator((prev) => ({
            ...prev,
            totalRecords: data?.data?.total,
            page: data?.data?.currentPage,
          }));
          setProductList(orderObj(medicalFields,dataToNameAndLabel(finalData, null, null,nonRequireMedicalListField,null,
            { medicine_name: "NAME"})))
          setListValues(finalData);
          setListLoading(false);
        } else {
          setNavigator((prev) => ({
            ...prev,
            totalRecords: 1,
            page: 1,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    init(navigator);
  }, [patientVisitId]);

  const onRowCountChange = (e) => {
    setListLoading(true);
    setNavigator((prev) => ({
      ...prev,
      rows: e?.target?.value,
      page: 1,
      first: 0,
    }));
    init({
      ...navigator,
      page: 1,
      first: 0,
      rows: e?.target?.value,
    });
  };

  function onPageChange(event) {
    setListLoading(true);
    setNavigator((prev) => ({
      ...prev,
      first: event?.first,
      page: event.page + 1,
    }));
    init({
      ...navigator,
      first: event?.first,
      page: event.page + 1,
    });
  }
  function handleSearchChange(value) {
    setListLoading(true);
    setNavigator((prev) => {
      const navigator = {
        ...prev,
        searchCriteria: {
          patient_visit_id: patientVisitId,
          medicine_name: value
        }
      };
      init(navigator); 
      return navigator;  
    });
  }

  return (
    <div>
      <div className="refraction-container p-3 pl-4">
        <div>
          <div
            className="public_sans_500 text-secondaryTextColor text-md pb-1 py-3 medical-prescription-header"
          >
            <div>Prescription</div>
          </div>
          {formData?.map((item, index) => {
            return renderInputHeader(
              item,
              index,
              setValue,
              watch,
              control,
              errors
            );
          })}
        </div>
        <div className="ml-2 pt-4">
          <AddButtonSection buttons={submitButtons} />
        </div>
      </div>
      <div className="mt-4 refraction-container">
        <DataGrid
          header={
            <ButtonWithSearch
            isSearchEnabled={true}
            searchPlaceHolder={"Search Prescription"}
            onChange={handleSearchChange}
            searchHeaderLable={"Medical Prescriptions"}
            />
          }
          onRowCountChange={onRowCountChange}
          onPageChange={onPageChange}
          navigator={navigator}
          column={productList}
          value={listValues}
          loading={listLoading}
          optionsView={[
            {
              items: [
                {
                  label: "Edit",
                  icon: "pi pi-upload",
                  template: itemRenderer,
                },
                {
                  label: "Remove",
                  icon: "pi pi-upload",
                  template: itemRenderer,
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
}
