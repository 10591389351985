import { Navigate } from "react-router-dom";

const checkUserAccess = () => {
  return true;
};

const ProtedtedRoute = ({ element, redirectTo = "/" }) => {
  const isAllowed = checkUserAccess();
  return isAllowed ? element : <div>You Don't Have Access</div>
  // <Navigate to={redirectTo} />;
};

export default ProtedtedRoute;
