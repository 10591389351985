import moment from "moment";
import _ from "lodash";
import InputHeaderWithWrap from "../components/inputHeaderWithWrap";
import { commonApi } from "./apiService";

export const getButtonClass = (type) => {
  switch (type) {
    case "primaryButton":
      return "bg-primaryButton font-medium text-15px border-round-lg";
    case "secondaryButton":
      return "bg-secoundaryButton font-medium text-15px border-round-lg";
    case "primarySuccessButton":
      return "bg-primarySuccessButtonColor font-medium text-15px border-round-lg";
    case "primaryCancelButton":
      return "bg-primaryCancelButtonColor font-medium text-15px border-round-lg";
    default:
      return "bg-primaryButton";
  }
};

export function convertaToName(item) {
  let finalName = "";
  if (item?.split("_")) {
    for (let word of item?.split("_")) {
      finalName =
        finalName +
        " " +
        word.split("")[0].toUpperCase() +
        word.split("").slice(1).join("");
    }
  }
  return finalName;
}
export function dataToNameAndLabel(
  data,
  remainProps,
  filterColumn,
  nonRequireField,
  arrageArray,
  alias
) {
  let keys = data && data[0] && Object.keys(data[0]);
  let convertedData = [];

  if (keys) {
    for (let [item, value] of Object.entries(data[0])) {
      if (nonRequireField?.includes(item)) continue;
      convertedData?.push({
        label: alias?.[item]
          ? convertaToName(alias?.[item])
          : item && convertaToName(item),
        name: item,
        filter: filterColumn?.includes(item),
        ...remainProps,
        // filter: true
      });
    }
  }
  if (arrageArray) {
    return arrageArrayFunc(convertedData, arrageArray);
  }
  return convertedData;
}

export function arrageArrayFunc(data, arrageArray) {
  if (!arrageArray || _.isEmpty(arrageArray)) return data;
  let arragedData = [];
  for (let item of arrageArray) {
    arragedData.push(data.find((el) => el.name === item));
  }
  return arragedData;
}

export function generateValidObject(data) {
  return Object.fromEntries(
    Object.entries(data).filter(([key, value]) => value)
  );
}

export function dateFormat(date, format) {
  return date ? moment(date)?.format(format?? "YYYY-MM-DD") : "";
}

export function addDays(date, days) {
  return moment(date)?.add(days, "days")?._d;
}

export const convetToSearchField = (keys, value) => {
  if (keys.length === 1) return { [keys[0]]: value };
  return keys?.reduce((obj, key) => {
    if (typeof obj === "string") {
      obj = { [obj]: value };
    }
    obj[key] = value;
    return obj;
  });
};

export const downloadBufferAsFile = (data, fileName = "image") => {
  const blob = new Blob([new Uint8Array(data)], { type: "image/png" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", fileName);
  a.click();
};

export const convertToNameLabel = (data, noNeed) => {
  let convertedData = {};
  if (data && Object.keys(data)?.length > 0) {
    for (let [item, value] of Object.entries(data)) {
      if (noNeed?.includes(item)) continue;
      convertedData[item] = {
        name: item.split("_").join(" "),
        value: value,
      };
    }
  }
  return convertedData;
};

export const setVal = (data, person, setValue = () => {}) => {
  data?.forEach((item) => {
    if (item.item) {
      setVal(item.item, person, setValue);
    } else {
      if (item.dataType === "date") {
        return setValue(
          item.name,
          moment(person?.[item.fieldLabel], "DD-MM-YYYY")?._d
        );
      }
      setValue(item.name, person?.[item.fieldLabel] || "");
    }
  });
};

export const pattern = (type, value) => {
  switch (type) {
    case "name":
      return /^[a-zA-Z0-9\s]+$/;
    case "cities":
      return /^[a-zA-Z\s]+$/;
    case "rationCardNo":
      return /^[a-zA-Z0-9]+$/;
    case "number":
      return /^[0-9]+$/;
    case "email":
      return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    case "address":
      return /^[^!@#$%^&*()\+=\[\]{};:'".<>\?\\|`~]+$/;
    case "pinCode":
      return /^[1-9][0-9]*$/;
    case "phoneNo":
      return /^(?!.*[+\-])[0-9]*$/;
    case "aarogyasri":
      return /^[0-9]+$/;

    case "aadharNo":
      return /^[2-9][0-9]*$/;

    default:
      return;
  }
};

export const listTabView = (type) => {
  switch (type) {
    case "primary":
      return "ListTabView__main";
    case "secondary":
      return "ListTabView__secondary";
    default:
      return "ListTabView__main";
  }
};

export let Util = {
  dataURLtoFile: (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  },
};

export default Util;

export const commonData = (fieldName, fieldWatch) => {
  return fieldName
    ?.map((item) => {
      if (fieldWatch?.includes(item?.value)) return item;
    })
    ?.filter((item) => item);
};

const commonRender = (item, index, setValue, watch, control, errors) => {
  return (
    <InputHeaderWithWrap
      type={item?.type}
      header={item?.header}
      control={control}
      errors={errors}
      customClass={item?.customClass}
      formRender={item?.formRender}
      value={item?.value}
      disabled={item?.disabled}
      onClick={(name, item) => {
        setValue(
          name,
          watch?.(name)?.filter((itemVal) => itemVal !== item)
        );
      }}
    />
  );
};
export const renderInputHeader = (
  item,
  index,
  setValue,
  watch,
  control,
  errors
) => {
  switch (item?.parentType) {
    case "plain":
      return commonRender(item, index, setValue, watch, control, errors);

    case "wrap-plain":
      return (
        <div className={item?.grandClass}>
          {item?.wrap?.map((item, index) => {
            return (
              <div className={item?.grandClass}>
                {commonRender(item, index, setValue, watch, control, errors)}
              </div>
            );
          })}
        </div>
      );

    case "wrap":
      return (
        <div className="flex flex-wrap max-w-full">
          {item?.wrap?.map((item, index) => {
            return (
              <div className="flex-1 basis-auto">
                {commonRender(item, index, setValue, watch, control, errors)}
              </div>
            );
          })}
        </div>
      );
    default:
      return <></>;
  }
};

export const fetchOptions = async (lookupTypeName, setOptions, showToast) => {
  const data = { lookup_type_name: lookupTypeName };
  try {
    const response = await commonApi("api/searchlookupdetails", data);
    const optionsData = response?.data?.data || [];
    const formattedOptions = optionsData.map((item) => ({
      label: item.name,
      value: item.lookup_id,
    }));
    setOptions(formattedOptions);
  } catch (err) {
    showToast("error", "Something went wrong");
  }
};

export const orderObj = (order, orderObj) => {
  return orderObj?.length > 0
    ? order.map((item) => orderObj.find((ite) => ite?.name == item))
    : [];
};

export const addLookUpOption = (data, init, showToast) => {
  try {
    commonApi("api/addlookupdetails", data).then((data) => {
      if (data) {
        init();
        // showToast(
        //   "success",
        //   `Lookup Data added successfully`);
      }
    });
  } catch (err) {
    showToast("error", "Something went wrong");
  }
};

export const dashoardDetails = (counts, dashboard) => {
  const patient = dashboard?.find((item) => item?.status === counts);
  return patient?.count || 0;
};

// Function to mask credit card number
export const mask = (mask) => {
  if (mask != null) {
    return mask.slice(-4).padStart(mask.length, "*");
  }
};

export function formatNumber(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(3) + "m"; // For millions
  } else if (num >= 1000) {
    return (num / 1000).toFixed(2) + "k"; // For thousands
  } else {
    return num.toString(); // If the number is less than 1k
  }
}

export const selectedPeriod = (
  period,
  format = "DD MMM YYYY",
  split = " - ",
  date
) => {
  switch (period) {
    case 1:
      if (format == "DD MMM YYYY") {
        return moment().format(format);
      } else return moment().format(format) + split + moment().format(format);

    case 2:
      return (
        moment().subtract(6, "days").format(format) +
        split +
        moment().format(format)
      );

    case 3:
      return (
        moment().subtract(29, "days").format(format) +
        split +
        moment().format(format)
      );
    case 4:
      return (
        (date?.[0] ? moment(date?.[0]).format(format) : "") +
        split +
        (date?.[1] ? moment(date?.[1]).format(format) : "")
      );
    case 5:
      return (
        (date?.[0] ? moment(date?.[0]).format(format) : "") +
        split +
        (date?.[1] ? moment(date?.[1]).format(format) : "")
      );

    default:
      return moment().format("DD MMM YYYY");
  }
};
