import React, { useState } from "react";
import AddButtonSection from "./AddButtonSection";
import PrimarySearch from "./primarySearch";
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";
import { Button } from "primereact/button";
import ArrivedImg from "../assets/images/arrived-user-image.svg";
export default function ButtonWithSearch({
  children,
  buttons,
  styles,
  searchHeaderLable,
  control,
  isSearchEnabled,
  secoundaryButton,
  placeholder,
  isCalenderEnabled,
  isButtonEnabledLeft,
  isButtonEnabledRight,
  handleDateChange = () => {},
  ...props
}) {
  const [date, setDate] = useState(null);

  const onCalenderChange = (e) => {
    handleDateChange(e?.value);
    setDate(e?.value);
  };
  return (
    <div className="search-header-holder">
      <div className="search-header-sec">
        <div className="table-sec-calendar">
          {!isCalenderEnabled && <span>{searchHeaderLable}</span>}

          {isCalenderEnabled && (
            <FloatLabel>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Calendar
                  id="buttondisplay"
                  inputId="today"
                  placeholder="today"
                  value={date}
                  onChange={onCalenderChange}
                  showIcon
                />
                {
                  <Button
                    icon="pi pi-times"
                    className="p-button-text p-button-danger pl-3"
                    onClick={() => onCalenderChange(null)}
                    aria-label="Clear Date"
                  />
                }
              </div>
              {!date && <label htmlFor="today">Select Date</label>}
            </FloatLabel>
          )}
          {isButtonEnabledLeft && (
            <AddButtonSection buttons={buttons} disabled={props?.loading} />
          )}

          {secoundaryButton && (
            <AddButtonSection
              buttons={secoundaryButton}
              disabled={props?.loading}
            />
          )}
        </div>

        <div className="search-btn-sec">
          {isButtonEnabledRight && (
            <AddButtonSection buttons={buttons} disabled={props?.loading} />
          )}
          {isSearchEnabled && (
            <PrimarySearch {...props} placeholder={placeholder} />
          )}
        </div>
      </div>
    </div>
  );
}
