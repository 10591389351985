import React, { useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "../assets/styles/dateRange.scss";
import AddButtonSection from "./AddButtonSection";

export default function DateRange({
  date,
  cancel = () => {},
  setDate,
  className = "customCare-dateRange",
}) {
  const [rangeDate, setRangeDate] = React.useState([{}]);

  const submitButtons = [
    {
      type: "secondaryButton",
      label: "Cancel",
      class: "w-7rem",
      onClick: () => {
        cancel();
      },
    },
    {
      type: "primarySuccessButton",
      label: "Ok",
      class: "w-6rem pl-3",
      // disabled: Object.keys(dirtyFields).length === 0 || loading,
      onClick: () => {
        setDate(rangeDate);
      },
    },
  ];
  useEffect(() => {
    if (date) {
      setRangeDate(date);
    }
  }, [date]);
  return (
    <div className="customCare-dateRange">
      <DateRangePicker
        onChange={(item) => setRangeDate([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={rangeDate}
        className={className}
        direction="horizontal"
      />

      <div className="flex justify-content-end">
        <AddButtonSection buttons={submitButtons} />
      </div>
    </div>
  );
}
