import React,{useState,useEffect} from "react";
import RefractorTable from "./refractorTable";
import { useForm } from "react-hook-form";
import AddButtonSection from "../../components/AddButtonSection";
import { commonApi } from "../../utils/apiService";
import { showToast } from "../../commonComponents/toast";

export default function GlassesPrescription({patientVisitId}) {
  const {  formState: { errors,dirtyFields },register,handleSubmit,control, setValue, watch,reset } = useForm();
  const [loading, setLoading] = useState(false);
  const headers = {
    "Content-Type": "application/json",
  };

  const onSubmit = async (data) => {
    if(loading)return;
    setLoading(true)
    const eyeExamination = {  
      patient_visit_id: patientVisitId,
      eyeTest: [
        {
          eye_type: "Left Eye",
          distance_axis: data?.L_Axis0Dry_Refraction || null,
          distance_necylar: data?.L_NeCylar0Dry_Refraction || null,
          distance_sph: data?.L_Sph0Dry_Refraction || null,
          distance_vision: data?.L_Vision0Dry_Refraction || null,
          near_axis: data?.L_Axis1Dry_Refraction || null,
          near_necylar: data?.L_NeCylar1Dry_Refraction || null,
          near_sph: data?.L_Sph1Dry_Refraction || null,
          near_vision: data?.L_Vision1Dry_Refraction || null,
          
        },
        {
          eye_type: "Right Eye",
          distance_axis: data?.R_Axis0Dry_Refraction || null,
          distance_necylar: data?.R_NeCylar0Dry_Refraction || null,
          distance_sph: data?.R_Sph0Dry_Refraction || null,
          distance_vision: data?.R_Vision0Dry_Refraction || null,
          near_axis: data?.R_Axis1Dry_Refraction || null,
          near_necylar: data?.R_NeCylar1Dry_Refraction || null,
          near_sph: data?.R_Sph1Dry_Refraction || null,
          near_vision: data?.R_Vision1Dry_Refraction || null,
        }
      ]
    }
    await commonApi("api/editEyeExaminationDetails", eyeExamination , {
      headers,
    })
    .then((data) => {
      if(data?.data?.success == true){
        showToast("success", `Updated Eye Examination`);
        setGlassPrescription()
        setLoading(false)
      }
    })
    .catch((err) => {
       showToast("error", "Something went wrong");
       setLoading(false)
    });
  }
  const submitButtons = [
    {
      type: "primarySuccessButton",
      label: "Save Changes",
      class: "w-11rem pr-3",
      onClick: handleSubmit(onSubmit),
    },
    {
      type: "secondaryButton",
      label: "Discard",
      class: "w-7rem",
      onClick: () => {
        discard()
      },
    },
  ];
  const discard = ()=>{
    fields.forEach(field => {
      setValue(field.name, '');
    });
  }
  const fields = [
    { name: "L_Sph0Dry_Refraction" , index : "distance_sph" },
    { name: "L_Vision0Dry_Refraction"  ,index : "distance_vision"},
    { name: "L_NeCylar0Dry_Refraction"  ,index : "distance_necylar"},
    { name: "L_Axis0Dry_Refraction" ,index : "distance_axis" },
    { name: "L_Sph1Dry_Refraction"  ,index : "near_sph"},
    { name: "L_Vision1Dry_Refraction"  ,index : "near_vision"},
    { name: "L_NeCylar1Dry_Refraction"  ,index : "near_necylar"},
    { name: "L_Axis1Dry_Refraction" ,index : "near_axis" },
    { name: "R_Sph0Dry_Refraction" ,index : "distance_sph" },
    { name: "R_Vision0Dry_Refraction" ,index : "distance_vision" },
    { name: "R_NeCylar0Dry_Refraction" ,index : "distance_necylar" },
    { name: "R_Axis0Dry_Refraction" ,index : "distance_axis" },
    { name: "R_Sph1Dry_Refraction" ,index : "near_sph" },
    { name: "R_Vision1Dry_Refraction"  ,index : "near_vision"},
    { name: "R_NeCylar1Dry_Refraction" ,index : "near_necylar" },
    { name: "R_Axis1Dry_Refraction" ,index : "near_axis" },
  ];

  useEffect(()=>{
    setGlassPrescription();
  },[patientVisitId])

  const setGlassPrescription = async () =>{
    const data = { patient_visit_id: patientVisitId };
    try {
      const response = await commonApi("api/searchEyeExaminationDetails", data, { headers });
      const formData = response?.data?.data || null;
      if(Object.keys(formData).length !== 0){
        const leftEyeData = formData.find(item => item.eye_type === "Left Eye");
        const rightEyeData = formData.find(item => item.eye_type === "Right Eye");
        fields.forEach(field => {
          let eyeData = field.name.startsWith('L_') ? leftEyeData : rightEyeData;
          let value = eyeData ? eyeData[field.index] : '';
          setValue(field.name, value || '');
        });
      }else if(Object.keys(formData).length === 0){
        discard();
      }
    } catch (err) {
      showToast("error", "Something went wrong");
    }
  }

  return (
    <div>
      <div>
        <RefractorTable
          contentTable={[
            { head: "L/DO Dry Refraction",eye:"L" },
            { head: "R/DO Dry Refraction",eye:"R" },
          ]}
          register={register}
        />
      </div>
      <div className="mt-6 ml-2">
        <AddButtonSection disabled={Object.keys(dirtyFields).length === 0 || loading} buttons={submitButtons} />
      </div>
    </div>
  );
}
