import React, { useEffect, useState } from "react";
import CalendarImg from "../assets/images/calendar.svg";
import SelectOption from "./selectButton";
import DateRangePopUp from "./dateRangePopUp";
import moment from "moment";
import { selectedPeriod } from "../utils/utils";

export default function DateSelect({
  show,
  setShow = () => {},
  patientDashboard = () => {},
  selectOption,
  setSelectOption = () => {},
  setDashboardDate,
}) {
  const [lastSelected, setLastSelect] = useState();
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    const period = selectedPeriod(1, "YYYY-MM-DD", "/")?.split("/");
    patientDashboard(period[0], period[1], selectOption);
  }, []);

  return (
    <div>
      <div className="flex align-items-center">
        <div className="flex">
          <img className="h-20px w-20px" src={CalendarImg} alt="calendar" />
          <span className="text-15px pl-2 flex align-items-end">
            {selectedPeriod(selectOption, "DD MMM YYYY", " - ", [
              date?.[0]?.startDate,
              date?.[0]?.endDate,
            ])}
          </span>
        </div>
        <DateRangePopUp
          show={show}
          setShow={setShow}
          date={date}
          setDate={(data) => {
            setDate(data);
            setDashboardDate(data);
            setLastSelect((prev) => ({  option: 4 }));
            patientDashboard(
              moment(data?.[0]?.startDate).format("YYYY-MM-DD"),
              moment(data?.[0]?.endDate).format("YYYY-MM-DD"),
              selectOption
            );
            show && setShow(false);
          }}
          lastSelected={lastSelected?.option}
          setSelectOption={setSelectOption}
        />
        <SelectOption
          className={"ml-auto"}
          setValue={(e) => {
            if (e === null && selectOption === 4) return setShow(true);
            if (e == null) return;
            const period = selectedPeriod(e, "YYYY-MM-DD", "/")?.split("/");
            if (e !== 4) {
              patientDashboard(period[0], period[1], e);
            }
            setSelectOption((prev) => {
              setLastSelect((pre) => ({
                ...pre,
                option: prev,
              }));
              return e;
            });
          }}
          setShow={setShow}
          options={[
            { name: "Today", value: 1 },
            { name: "7 Days", value: 2 },
            { name: "30 Days", value: 3 },
            {
              name: (
                <div className="flex">
                  <span>Select Date</span>
                  <span className="pl-2 align-items-center flex">
                    <i className="icon-Date" />
                  </span>
                </div>
              ),
              value: 4,
            },
          ]}
          value={selectOption}
        />
      </div>
    </div>
  );
}
