import React from "react";
import { Controller, useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils";
import "../assets/styles/inputComponent.scss";
import { InputNumber } from "primereact/inputnumber";
import { Password } from "primereact/password";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";
import { AutoComplete } from "primereact/autocomplete";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";

export default function InputComponent({
  control,
  rules,
  name = "",
  keyfilter,
  options = [],
  errors = {},
  dateFormat = "dd/mm/yy",
  mask = "99/99/9999",
  ...props
}) {
  const { register } = useForm();
  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      ""
    );
  };
  switch (props.type) {
    case "text":
      return (
        <div className="input-standerd-container h-inherit">
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <InputText
                  id={field.name}
                  {...field}
                  autoFocus={props.autoFocus}
                  keyfilter={keyfilter}
                  placeholder={props.placeholder}
                  disabled={props?.disabled}
                  className={classNames({ "p-invalid": fieldState.invalid })}
                  maxLength={props?.maxLength} 
                />
              </React.Fragment>
            )}
          />
        </div>
      );

    case "number":
      return (
        <div className="input-standerd-container h-inherit">
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => {
              return (
                <React.Fragment>
                  <InputNumber
                    id={field.name}
                    ref={field.ref}
                    value={field.value ?? ""}
                    onBlur={field.onBlur}
                    onValueChange={(e) => field.onChange(e.value)}
                    disabled={props?.disabled}
                    maxLength={props?.maxLength} 
                    inputClassName={classNames({
                      "p-invalid": fieldState.error,
                    })}
                  />
                  {/* {getFormErrorMessage(field.name)} */}
                </React.Fragment>
              );
            }}
          />
        </div>
      );

    case "currency":
      return (
        <div className="input-standerd-container h-inherit">
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => {
              return (
                <React.Fragment>
                  <IconField iconPosition="left">
                    <InputIcon> <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 3.33301H4.66663H6.66663C8.13939 3.33301 9.33329 4.52692 9.33329 5.99967C9.33329 7.47243 8.13939 8.66634 6.66663 8.66634H4.66663L8.66663 12.6663" stroke="#2F2B3D" stroke-opacity="0.9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M4.66663 6.00033H12" stroke="#2F2B3D" stroke-opacity="0.9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </InputIcon>
                    <InputNumber
                      id={field.name}
                      ref={field.ref}
                      value={field.value ?? ""}
                      onBlur={field.onBlur}
                      onValueChange={(e) => field.onChange(e.value)}
                      disabled={props?.disabled}
                      min={0}
                      inputClassName={classNames({
                        "p-invalid": fieldState.error,
                      })}
                      placeholder={props.placeholder}
                    />
                  </IconField>
                  {/* {getFormErrorMessage(field.name)} */}
                </React.Fragment>
              );
            }}
          />
        </div>
      );

    case "radio":
      return (
        <div className="input-standerd-container h-inherit">
          <div className="radio-label">{props?.label}</div>
          <React.Fragment>
            <div className="flex flex-wrap gap-3">
              {props?.radioMenu?.map?.((item, index) => (
                <div className="flex align-items-center gap-2">
                  <Controller
                    name={item?.name}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <RadioButton
                          inputId={item?.inputId}
                          value={item?.inputId}
                          onChange={(e) => field.onChange(e.value)}
                          checked={field.value === item?.inputId}
                          disabled={props?.disabled}
                        />
                        <label htmlFor="male" className="label-head">
                          {item?.label}
                        </label>
                      </>
                    )}
                  />
                </div>
              ))}
            </div>
          </React.Fragment>
        </div>
      );

    case "password":
      return (
        <div className="input-standerd-container h-inherit">
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <Password
                  id={field.name}
                  {...field}
                  feedback={false}
                  placeholder={props.placeholder}
                  disabled={props?.disabled}
                  className={classNames({ "p-invalid": fieldState.invalid })}
                />
                {/* {getFormErrorMessage(field.name)} */}
              </React.Fragment>
            )}
          />
        </div>
      );

    case "calendar":
      return (
        <div className="input-standerd-container h-inherit">
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <React.Fragment>
                <Calendar
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  dateFormat={dateFormat}
                  disabled={props?.disabled}
                  placeholder={props?.placeholder}
                  mask={mask}
                  className={props?.className || "calender-std-height"}
                  minDate={props?.minDate}
                  maxDate={props?.maxDate}
                  showIcon
                />
                {/* {getFormErrorMessage(field.name)} */}
              </React.Fragment>
            )}
          />
        </div>
      );

    case "dropdown":
      return (
        <div className="input-standerd-container h-inherit">
          <Controller
            name={name}
            rules={rules}
            control={control}
            render={({ field }) => (
              <React.Fragment>
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={options}
                  placeholder={props.placeholder}
                  disabled={props?.disabled}
                  optionLabel="label"
                />
                {/* {getFormErrorMessage(field.name)} */}
              </React.Fragment>
            )}
          />
        </div>
      );

    case "multiselect":
      return (
        <div className="input-standerd-container h-inherit">
          <Controller
            name={name}
            rules={rules}
            control={control}
            render={({ field }) => (
              <React.Fragment>
                <MultiSelect
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={options}
                  optionLabel="label"
                  disabled={props?.disabled}
                  placeholder={props.placeholder}
                  panelFooterTemplate={(a, b, c) => {
                    if (props?.nestedOnClick)
                      return (
                        <InputComponent
                          notMandatory
                          type={"examText"}
                          button
                          input={props?.nestedInput}
                          optionLabel={"name"}
                          onClick={props?.nestedOnClick}
                          placeholder={props?.nestedPlaceHolder}
                          dateInputClassName={"pl-4"}
                          panelClassName={
                            "dropDown-panel inter-500 text-grey-least text-sm examDropdown"
                          }
                        />
                      );
                    return false;
                  }}
                  filter
                />
                {/* {getFormErrorMessage(field.name)} */}
              </React.Fragment>
            )}
          />
        </div>
      );

    case "dropdown-std":
      return (
        <div className="input-standerd-container h-inherit">
          <React.Fragment>
            <Dropdown
              id={name}
              value={props?.value}
              onChange={props?.onChange}
              options={options}
              optionLabel="label"
              disabled={props?.disabled}
              placeholder={props.placeholder}
            />
          </React.Fragment>
        </div>
      );

      case "dropdown-add":
        return (
          <div className="input-standerd-container h-inherit">
            <Controller
              name={name}
              rules={rules}
              control={control}
              render={({ field }) => (
                <React.Fragment>
                  <Dropdown
                     panelFooterTemplate={(a, b, c) => {
                      if (props?.nestedOnClick)
                        return (
                          <InputComponent
                            notMandatory
                            type={"examText"}
                            button
                            input={props?.nestedInput}
                            optionLabel={"name"}
                            onClick={props?.nestedOnClick}
                            dateInputClassName={"pl-4"}
                            panelClassName={
                              "dropDown-panel inter-500 text-grey-least text-sm examDropdown"
                            }
                          />
                        );
                      return false;
                    }}
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={options}
                    disabled={props?.disabled}
                    optionLabel="label"
                    placeholder="Select"
                  />
                  {/* {getFormErrorMessage(field.name)} */}
                </React.Fragment>
              )}
            />
          </div>
        );

    

    case "autocomplete":
      return (
        <div className="input-standerd-container h-inherit w-full">
          <AutoComplete
            field={props?.field}
            value={props?.value}
            disabled={props?.disabled}
            suggestions={props?.items}
            placeholder={props.placeholder}
            completeMethod={props?.search}
            onChange={(e) => props?.onChange?.(e.value)}
          />
        </div>
      );

    case "examText":
      return (
        <div className={`input-content dropDown ${props?.textInputClassName}`}>
          <div className="flex align-items-center p-2 pl-20px pr-1">
            <input
              type={"text"}
              className={`examText border-round-sm border-grey-refer w-full h-2rem`}
              {...props.input}
            />
            {
              <span className="p-2" onClick={(e) => props?.onClick?.(e, props)}>
                <i className="pi pi-plus cursor-pointer" />
              </span>
            }
          </div>
        </div>
      );

    default:
      return <React.Fragment></React.Fragment>;
  }
}
