import React, { useState } from "react";
import Payment from "./payment";
import SecoundaryReportCard from "../../components/secoundaryReportCard";
import PersonImg from "../../assets/images/person.svg"
import Balance from "../../assets/images/balance.svg"
import PaidImg from "../../assets/images/paidAmount.svg"
import { formatNumber } from "../../utils/utils";


export default function PaymentDetails({ patientVisitId, patientId }) {
  const [invoiceDetails, setInvoiceDetails] = useState({});
  return (
    <div>
      <div>
        <SecoundaryReportCard
          reportList={[
            {
              title: "Total Amount",
              count: `${(invoiceDetails?.totalAmount? formatNumber(invoiceDetails?.totalAmount): 0)}`,
              icon: PersonImg,
            },
            {
              title: "Paid",
              count: `${(invoiceDetails?.paid? formatNumber(invoiceDetails?.paid): 0)}`,
              icon: PaidImg,
            },
            {
              title: "Balance",
              count: `${(invoiceDetails?.balance? formatNumber(invoiceDetails?.balance): 0)}`,
              icon: Balance,
            },
          ]}
        />
      </div>
      <div>
        <Payment
          api="/api/getAllCollectablePayments"
          accordion
          noRequired={[
            "payment_categories",
            "patient_id",
            "patient_name",
            "patient_code",
          ]}
          patientId={patientId}
          setInvoiceDetails={setInvoiceDetails}
          headerAlias={{
            collectable_payment_description_id: "invoice",
            InvoiceDate: "Date",
            total_collectable_amount: "total_amount",
            total_paid_amount: "amount_paid",
            total_balance_amount: "balance",
          }}
          requiredButton={["Edit", "Add Payment"]}
        />
      </div>
    </div>
  );
}
