import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../assets/styles/customCalendar.scss";

function CustomCalendar({
  inline = true,
  selectedDate = new Date(),
  setSelectedDate = () => {},
  calendarClassName = "custom-calendar",
}) {
  return (
    <div>
      <DatePicker
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        dateFormat="dd-MM-yyyy"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        inline={inline} // This renders only the calendar without the input field
        calendarClassName={calendarClassName}
      />
    </div>
  );
}

export default CustomCalendar;
