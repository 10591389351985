import { DataTable } from "primereact/datatable";
import React from "react";
import { actionBodyTemplate, columnContent } from "./service";
import { Paginator } from "primereact/paginator";
import { Column } from "primereact/column";
import _ from "lodash";
import "../../assets/styles/dataGrid.scss";
import editIcon from "../../assets/images/editIcon.svg"
import InputComponent from "../inputComponent";

export default function DataGrid({
  value,
  column,
  loading,
  dataKey,
  urlPath,
  sortable,
  selection,
  concernId,
  navigator,
  selectable,
  excColumns,
  tableStyle,
  optionsView,
  isShowEdit,
  filterColumn = [],
  selectionMode = "multiple",
  columnClickable,
  detailUrlPrefix,
  tableClassName,
  expandedRows,
  isAccordionEnabled,
  isPaginatorEnabled = true,
  onRowClick = () => {},
  onPageChange = () => {},
  onSortClick = () => {},
  onRowCountChange = () => {},
  onSelectionChange = () => {},
  onRowToggle = () => {},
  onRowExpand = () => {},
  onRowCollapse = () => {},
  rowExpansionTemplate = () => {},
  allowExpansion = () => {},
  ...props
}) {
  const leftContent = (e) => {
    const currentMax = e?.rows * navigator?.page;
    return (
      <div className="flex rc-patient-flex">
        <span className="rc-patient-option-rows">
          <InputComponent
            type="dropdown-std"
            placeholder="select a range"
            options={[5, 10, 25, 50, 100]}
            value={navigator?.rows ? navigator?.rows : 1}
            onChange={onRowCountChange}
          />
        </span>
        <span className="pl-3 text-commonConten public_sans_400 text-15px">
          {`Showing ${
            e?.first +
            1 +
            " - " +
            (currentMax < e?.totalRecords ? currentMax : e?.totalRecords)
          } of ${e?.totalRecords}`}
        </span>
      </div>
    );
  };

  return (
    <div className="primary-box-shadow bg-white border-round-md">
      <div className="data-grid-header"></div>
      {props?.header}
      <div className="data__grid-container">
        {
          <DataTable
            editMode={"row"}
            onSort={onSortClick}
            sortField={navigator?.sortBy}
            removableSort={props?.removableSort}
            sortOrder={navigator?.sortDir == "asc" ? 1 : -1}
            onRowClick={onRowClick}
            value={value}
            dataKey={dataKey}
            selection={selection}
            selectionMode={"checkbox"}
            onSelectionChange={onSelectionChange}
            tableStyle={tableStyle}
            className="data-grid-table-body"
            tableClassName={tableClassName}
            emptyMessage="No results found."
            scrollable
            loading={loading}
            expandedRows={expandedRows}
            onRowToggle={onRowToggle}
            onRowExpand={onRowExpand}
            onRowCollapse={onRowCollapse}
            rowExpansionTemplate={rowExpansionTemplate}
            allowExpansion={allowExpansion}
          >
            {isAccordionEnabled && (
              <Column expander={true} style={{ width: "5rem" }} />
            )}
            {selectable && (
              <Column
                selectionMode={selectionMode}
                className="selection-check-box"
                headerStyle={{ width: "3rem" }}
              ></Column>
            )}

            {columnContent(
              "bigBold",
              column,
              detailUrlPrefix,
              concernId,
              columnClickable,
              urlPath,
              filterColumn,
              excColumns,
              sortable
            )}
            {isShowEdit && (
              <Column
              body={() => (
                <button
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    verticalAlign: 'text-top'
                  }}
                >
                  <img src={editIcon} alt="" />
                </button>
              )}
            />
            )}
            
            {_.isArray(optionsView) && !_.isEmpty(optionsView) && (
              <Column
                body={(e) => actionBodyTemplate(e, optionsView, props.ref)}
                exportable={false}
                className="action-column"
              ></Column>
            )}
            
          </DataTable>
        }
        {isPaginatorEnabled && (
          <Paginator
            leftContent={leftContent}
            // template={paginatorTemplate}
            first={navigator?.first ? navigator?.first : 0}
            rows={navigator?.rows ? navigator?.rows : 1}
            totalRecords={navigator?.totalRecords ? navigator.totalRecords : 1}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </div>
  );
}
