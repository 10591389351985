import { Dialog } from "primereact/dialog";
import React from "react";
import DateRange from "./dateRange";

export default function DateRangePopUp({
  show,
  setShow,
  date,
  setDate,
  setSelectOption,
  lastSelected,
}) {
  const closeModal = () => {
    setShow(false);
    setSelectOption(lastSelected);
  };
  return (
    <div>
      <Dialog
        header={"Select Date Range"}
        visible={show}
        style={{ width: "auto" }}
        onHide={closeModal}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <DateRange
            date={date}
            setDate={(e) => {
              setDate(e);
            }}
            cancel={closeModal}
          />
        </div>
      </Dialog>
    </div>
  );
}
