import React from "react";
import ListTabView from "../../commonComponents/tabView";
import RefractorTable from "./refractorTable";
import { useForm } from "react-hook-form";
import AddButtonSection from "../../components/AddButtonSection";
import SurgeryRecommendation from "./surgeryRecommendation";
import GlassesPrescription from "./glassesPrescription";
import MedicalPrescription from "./medicalPrescription";

export default function Treatement({ patientVisitId }) {
  return (
    <div>
      <div className="my-2 public_sans_500 text-lg text-secondaryTextColor">
        Treatment
      </div>
      <div className="mt-4">
        <ListTabView
          type={"secondary"}
          defaultIndex={0}
          listComponent={[
            {
              header: "Medical Prescription",
              content: <MedicalPrescription patientVisitId={patientVisitId} />,
            },
            {
              header: "Glasses Prescription",
              content: <GlassesPrescription patientVisitId={patientVisitId} />,
            },
            {
              header: "Surgery Recommendation",
              content: (
                <SurgeryRecommendation patientVisitId={patientVisitId} />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
}
