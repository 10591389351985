import React, { useEffect, useState, useRef } from "react";
import { Calendar } from "primereact/calendar";
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from "primereact/progressspinner";
import CalendarIcon from "../assets/images/calendar.svg";
import moment from "moment";
import _ from "lodash";
import "./Reports.scss";
import { commonApi } from "../utils/apiService";

function ViewReport(props) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [dates, setDates] = useState(null);
  const [chartData, setChartData] = useState({});
  const [chartSurgery, setChartSurgery] = useState({});
  const [chartPatient, setChartPatient] = useState({});
  const maxDate = new Date();

  const calendarRef = useRef(null);

  const defaultoptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const apiCall = async (payload) => {
    setLoading(true);
    let monthData = {
      labels:[],
      surgery: [],
      examination: [],
      patient: [],
    };
    let report = await commonApi("api/report", payload);
    let [reportData] = await Promise.all([report]);
    reportData?.data?.data?.[0].forEach(item => {
      monthData?.labels.push(item?.Label);
      monthData?.examination.push(item?.Examinations);
      monthData?.surgery.push(item?.Surgeries);
      monthData?.patient.push(item?.Patients);
    })
    const totalExaminations = reportData?.data?.data?.[0].reduce((acc, item) => acc + item.Examinations, 0);
    const totalSurgeries = reportData?.data?.data?.[0].reduce((acc, item) => acc + item.Surgeries, 0);
    const totalPatients = reportData?.data?.data?.[0].reduce((acc, item) => acc + item.Patients, 0);
    const clinicalAssesment = {
      labels: monthData?.labels || [],
      datasets: [
          {
              label: 'Clinical Assessment',
              data: monthData?.examination || [],
              backgroundColor: [
                  'rgba(255, 159, 64, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(153, 102, 255, 0.2)'
                ],
                borderColor: [
                  'rgb(255, 159, 64)',
                  'rgb(75, 192, 192)',
                  'rgb(54, 162, 235)',
                  'rgb(153, 102, 255)'
                ],
                borderWidth: 1
          }
      ]
    };
    const surgeries = {
      labels: monthData?.labels || [],
      datasets: [
          {
              label: 'Surgeries',
              data: monthData?.surgery || [],
              backgroundColor: [
                  'rgba(255, 159, 64, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(153, 102, 255, 0.2)'
                ],
                borderColor: [
                  'rgb(255, 159, 64)',
                  'rgb(75, 192, 192)',
                  'rgb(54, 162, 235)',
                  'rgb(153, 102, 255)'
                ],
                borderWidth: 1
          }
      ]
    };
    const patienties = {
      labels: monthData?.labels || [],
      datasets: [
          {
              label: 'Patients',
              data: monthData?.patient || [],
              backgroundColor: [
                  'rgba(255, 159, 64, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(153, 102, 255, 0.2)'
                ],
                borderColor: [
                  'rgb(255, 159, 64)',
                  'rgb(75, 192, 192)',
                  'rgb(54, 162, 235)',
                  'rgb(153, 102, 255)'
                ],
                borderWidth: 1
          }
      ]
    };
    setChartData({
      ...clinicalAssesment,
      totalExaminations
    });
  setChartSurgery({
    ...surgeries,
    totalSurgeries
  })
  setChartPatient({
    ...patienties,
    totalPatients
  })
  };

  useEffect(() => {
    setDates([
      new Date(moment().subtract(5, "months").startOf("month")),
      new Date(),
    ]);
  }, []);

  useEffect(() => {
    if (dates?.[0]!=null && dates?.[1]!=null) {
      const dateRange = {
        startDate: moment(dates[0]).format("YYYY-MM-DD"),
        endDate: moment(dates[1]).format("YYYY-MM-DD")
      };
      apiCall(dateRange);
      setShow(true)
    }else if(!dates || dates?.[0]!=null){
      setShow(false)
      const dateRange = {
        startDate: null,
        endDate: null
      };
      apiCall(dateRange);
    }
  }, [dates]);

  
  const updateReportDateRange = (val) => {
    setDates(val);
  };

  return (
    <div>
      <div>
        <span>
          <div className="card flex mr-3 mt-2">
            <span className="p-float-label ml-auto">
              <Calendar
                inputId="report_duration"
                value={dates}
                onChange={(e) => updateReportDateRange(e.value)}
                selectionMode="range"
                icon={<img src={CalendarIcon} />}
                showIcon
                showButtonBar
                maxDate={maxDate}
                ref={calendarRef}
              />
              <label htmlFor="report_duration">Report Duration</label>
            </span>
          </div>
        </span>
      </div>
      <div className="report-container">
        {loading == "ll" ? (
          <div className="flex">
            <ProgressSpinner />
          </div>
        ) : (
          <>
            <div className="grid report-overview">
              <div className="col overview-cols overview-col-1">
                <div className="overview-data text-center p-3 border-round-sm">
                  <div className="overview-num">
                    {chartData.totalExaminations || 0}
                  </div>
                  <div className="overview-title">
                    Total Clinical Assessment
                  </div>
                </div>
              </div>
              <div className="col overview-cols overview-col-2">
                <div className="overview-data text-center p-3 border-round-sm">
                  <div className="overview-num">
                  {chartSurgery.totalSurgeries || 0}
                  </div>
                  <div className="overview-title">Total Surgery</div>
                </div>
              </div>
              <div className="col overview-cols overview-col-4">
                <div className="overview-data text-center p-3 border-round-sm">
                  <div className="overview-num">
                    {chartPatient?.totalPatients || 0}
                  </div>
                  <div className="overview-title">Total Patients</div>
                </div>
              </div>
            </div>
            {
              show ? (
                <>
              <div className="grid">
              <div className="col">
                <div className="text-center p-3 chart-container">
                <Chart type="bar" data={chartData} options={defaultoptions} />
                  <div className="chart-title">
                    Clinical Assessment
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="text-center p-3 chart-container">
                <Chart type="bar" data={chartSurgery} options={defaultoptions} />
                  <div className="chart-title">
                    Surgery
                  </div>
                </div>
              </div>
            </div>
              </>
              ) : (
                <div>
                  <p>No Date Selected</p>
                </div>
              )
            }
          </>
        )}
      </div>
    </div>
  );
}

export default ViewReport;
