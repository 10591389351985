import React,{useState,useEffect} from "react";
import InputHeaderWithWrap from "../../components/inputHeaderWithWrap";
import { Panel } from "primereact/panel";
import "../../assets/styles/examination.scss";
import AddButtonSection from "../../components/AddButtonSection";
import { useForm } from "react-hook-form";
import _ from "lodash";
import RefractorTable from "./refractorTable";
import { commonApi } from "../../utils/apiService";
import { DevTool } from "@hookform/devtools";
import { showToast } from "../../commonComponents/toast";

export default function EyeExam({patientVisitId}) {
  const ref = React.useRef();
  const { formState: { errors,dirtyFields },register,handleSubmit,control, setValue, watch,reset} = useForm({});
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  let headers = {
    "Content-Type": "application/json",
  };
  const totalForm = [
    {
      formName:"totalForm",
      parentType: "wrap",
      wrap: [
        {
          items: [
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              header: "L/DO",
              customClass: "py-1 customInput pt-3",
              formRender: [
                {
                  type: "text",
                  name: "L_visual_acuity",
                  label: "Visual Acuity",
                  fieldLabel: "L_visual_acuity",
                  placeholder: "Type here",
                  inputParentClass: "w-full",
                },
              ],
            },
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              formRender: [
                {
                  type: "text",
                  name: "L_intraocular_pressure",
                  label: "Intraocular Pressure",
                  fieldLabel: "intraocular_pressure",
                  placeholder: "Type here",
                  inputParentClass: "w-full ",
                },
              ],
            },
          ],
        },
        {
          items: [
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              header: "R/DO",
              customClass: "py-1 customInput pt-3",
              formRender: [
                {
                  type: "text",
                  name: "R_visual_acuity",
                  label: "Visual Acuity",
                  fieldLabel: "R_visual_acuity",
                  placeholder: "Type here",
                  inputParentClass: "w-full ",
                },
              ],
            },
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              formRender: [
                {
                  type: "text",
                  name: "R_intraocular_pressure",
                  label: "Intraocular Pressure",
                  fieldLabel: "R_intraocular_pressure",
                  placeholder: "Type here",
                  inputParentClass: "w-full ",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const optionalForm = [
    {
      formName:"optionalForm",
      parentType: "wrap",
      wrap: [
        {
          items: [
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              customClass: "py-1 customInput",
              formRender: [
                {
                  type: "text",
                  name: "auto_refraction",
                  label: "Auto Refraction",
                  fieldLabel: "auto_refraction",
                  placeholder: "Type here",
                  inputParentClass: "w-full ",
                },
              ],
            },
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              formRender: [
                {
                  type: "text",
                  name: "refraction",
                  label: "Refraction (Dilated)",
                  fieldLabel: "refraction",
                  placeholder: "Type here",
                  inputParentClass: "w-full ",
                },
              ],
            },
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              formRender: [
                {
                  type: "text",
                  name: "pgp",
                  label: "PGP",
                  fieldLabel: "pgp",
                  placeholder: "Type here",
                  inputParentClass: "w-full ",
                },
              ],
            },
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              formRender: [
                {
                  type: "dropdown",
                  name: "glasses_prescription",
                  label: "Glasses Prescription",
                  fieldLabel: "glasses_prescription",
                  placeholder: "Select",
                  inputParentClass: "w-full ",
                  options: [
                    {
                      label: "Yes",
                      value: "yes",
                    },
                    {
                      label: "No",
                      value: "no",
                    },
                  ],
                },
              ],
            },
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              formRender: [
                {
                  type: "text",
                  name: "intermediate_glasses_prescription",
                  label: "Intermediate Glasses Prescription",
                  fieldLabel: "intermediate_glasses_prescription",
                  placeholder: "Type here",
                  inputParentClass: "w-full ",
                },
              ],
            },
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              formRender: [
                {
                  type: "text",
                  name: "pmt",
                  label: "PMT",
                  fieldLabel: "pmt",
                  placeholder: "Type here",
                  inputParentClass: "w-full ",
                },
              ],
            },
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              formRender: [
                {
                  type: "text",
                  name: "retinoscopy",
                  label: "Retinoscopy",
                  fieldLabel: "retinoscopy",
                  placeholder: "Type here",
                  inputParentClass: "w-full ",
                },
              ],
            },
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              formRender: [
                {
                  type: "text",
                  name: "keratometry",
                  label: "Keratometry",
                  fieldLabel: "keratometry",
                  placeholder: "Type here",
                  inputParentClass: "w-full ",
                },
              ],
            },
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              formRender: [
                {
                  type: "text",
                  name: "amsler",
                  label: "Amsler",
                  fieldLabel: "amsler",
                  placeholder: "Type here",
                  inputParentClass: "w-full ",
                },
              ],
            },{
              type: "input-group",
              parentType: "wrap-with-flex",
              formRender: [
                {
                  type: "text",
                  name: "contact_lens_prescription",
                  label: "Contact Lens Prescription",
                  fieldLabel: "contact_lens_prescription",
                  placeholder: "Type here",
                  inputParentClass: "w-full ",
                },
              ],
            },
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              formRender: [
                {
                  type: "text",
                  name: "color_vision",
                  label: "Color Vision",
                  fieldLabel: "color_vision",
                  placeholder: "Type here",
                  inputParentClass: "w-full ",
                },
              ],
            },
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              formRender: [
                {
                  type: "text",
                  name: "contrast_sensitivity",
                  label: "Contrast Sensitivity",
                  placeholder: "Type here",
                  fieldLabel: "contrast_sensitivity",
                  inputParentClass: "w-full ",
                },
              ],
            },
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              formRender: [
                {
                  type: "text",
                  name: "orthoptics",
                  label: "Orthoptics",
                  placeholder: "Type here",
                  fieldLabel: "orthoptics",
                  inputParentClass: "w-full ",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const surgeryCheck = [
    {
      formName:"totalForm",
      parentType: "wrap",
      wrap: [
        {
          items: [
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              customClass: "py-1 customInput pt-5",
              formRender: [
                {
                  type: "dropdown",
                  name: "surgery_recommended",
                  label: "Surgery Recommended",
                  fieldLabel: "surgery_recommended",
                  placeholder: "Select",
                  inputParentClass: "w-full",
                  options: [
                    {
                      label: "Yes",
                      value: "yes",
                    },
                    {
                      label: "No",
                      value: "no",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          items: [
            {
              type: "input-group",
              parentType: "wrap-with-flex",
              customClass: "py-1 customInput pt-5",
              formRender: [
                {
                  type: "text",
                  name: "others",
                  label: "Other",
                  fieldLabel: "others",
                  placeholder: "Type here",
                  inputParentClass: "w-full",
                },
              ],
            }
          ],
        },
      ],
    },
  ];

  const fields = [
    { name: "L_Sph0Dry_Refraction" , index : "distance_sph" },
    { name: "L_Vision0Dry_Refraction"  ,index : "distance_vision"},
    { name: "L_NeCylar0Dry_Refraction"  ,index : "distance_necylar"},
    { name: "L_Axis0Dry_Refraction" ,index : "distance_axis" },
    { name: "L_Sph1Dry_Refraction"  ,index : "near_sph"},
    { name: "L_Vision1Dry_Refraction"  ,index : "near_vision"},
    { name: "L_NeCylar1Dry_Refraction"  ,index : "near_necylar"},
    { name: "L_Axis1Dry_Refraction" ,index : "near_axis" },
    { name: "L_intraocular_pressure" ,index : "iop" },
    { name: "L_visual_acuity" ,index : "va" },
    { name: "L_auto_refraction"  ,index : "auto_refraction"},
    { name: "L_contrast_sensitivity"  ,index : "contrast_sensitivity"},
    { name: "L_refraction" ,index : "refraction_dilated" },
    { name: "L_orthoptics"  ,index : "orthoptics"},
    { name: "L_pgp" ,index : "pgp" },
    { name: "L_intermediate_glasses_prescription"  ,index : "intermediate_glasses_prescription"},
    { name: "L_pmt" ,index : "pmt" },
    { name: "L_retinoscopy" ,index : "retinoscopy" },
    { name: "L_keratometry" ,index : "keratometry" },
    { name: "L_contact_lens_prescription"  ,index : "contact_lens_prescription"},
    { name: "L_amsler"  ,index : "amsler"},
    { name: "L_color_vision" ,index : "color_vision" },
    { name: "R_visual_acuity" ,index : "va" },
    { name: "R_auto_refraction"  ,index : "auto_refraction"},
    { name: "R_intraocular_pressure" ,index : "iop" },
    { name: "R_contrast_sensitivity" ,index : "contrast_sensitivity" },
    { name: "R_refraction" ,index : "refraction_dilated" },
    { name: "R_orthoptics"  ,index : "orthoptics"},
    { name: "R_pgp" ,index : "pgp" },
    { name: "R_intermediate_glasses_prescription" ,index : "intermediate_glasses_prescription" },
    { name: "R_pmt"  ,index : "pmt"},
    { name: "R_retinoscopy" ,index : "retinoscopy" },
    { name: "R_keratometry" ,index : "keratometry" },
    { name: "R_contact_lens_prescription" ,index : "contact_lens_prescription" },
    { name: "R_amsler"  ,index : "amsler"},
    { name: "R_color_vision" ,index : "color_vision" },
    { name: "R_Sph0Dry_Refraction" ,index : "distance_sph" },
    { name: "R_Vision0Dry_Refraction" ,index : "distance_vision" },
    { name: "R_NeCylar0Dry_Refraction" ,index : "distance_necylar" },
    { name: "R_Axis0Dry_Refraction" ,index : "distance_axis" },
    { name: "R_Sph1Dry_Refraction" ,index : "near_sph" },
    { name: "R_Vision1Dry_Refraction"  ,index : "near_vision"},
    { name: "R_NeCylar1Dry_Refraction" ,index : "near_necylar" },
    { name: "R_Axis1Dry_Refraction" ,index : "near_axis" },
    { name: "others" ,index : "others" },
  ];

  const booleanFields = [
    { name: "surgery_recommended" ,index : "surgery_recommended" },
    { name: "R_glasses_prescription" ,index : "glasses_prescription" },
    { name: "L_glasses_prescription" ,index : "glasses_prescription" },
  ]
  
  useEffect(()=>{
    getEyeExam();
  },[patientVisitId])

  const onSubmit = async (data) => {
    if(loading)return;
    setLoading(true)
    const eyeExamination = {  
      patient_visit_id: patientVisitId,
      eyeTest: [
        {
          eye_type: "Left Eye",
          iop: data?.L_intraocular_pressure || null,
          distance_axis: data?.L_Axis0Dry_Refraction || null,
          distance_necylar: data?.L_NeCylar0Dry_Refraction || null,
          distance_sph: data?.L_Sph0Dry_Refraction || null,
          distance_vision: data?.L_Vision0Dry_Refraction || null,
          near_axis: data?.L_Axis1Dry_Refraction || null,
          near_necylar: data?.L_NeCylar1Dry_Refraction || null,
          near_sph: data?.L_Sph1Dry_Refraction || null,
          near_vision: data?.L_Vision1Dry_Refraction || null,
          va: data?.L_visual_acuity || null,
          auto_refraction: data?.L_auto_refraction || null,
          contrast_sensitivity: data?.L_contrast_sensitivity || null,
          refraction_dilated: data?.L_refraction || null,
          orthoptics: data?.L_orthoptics || null,
          glasses_prescription: data?.L_glasses_prescription === "yes" ? true : false ,
          pgp: data?.L_pgp || null,
          intermediate_glasses_prescription: data?.L_intermediate_glasses_prescription || null,
          pmt: data?.L_pmt || null,
          retinoscopy: data?.L_retinoscopy || null,
          keratometry: data?.L_keratometry || null,
          contact_lens_prescription: data?.L_contact_lens_prescription || null,
          amsler: data?.L_amsler || null,
          color_vision: data?.L_color_vision || null,
          surgery_recommended:data?.surgery_recommended === "yes" ? true : false,
           others : data?.others || ""
        },
        {
          eye_type: "Right Eye",
          iop: data?.R_intraocular_pressure || null,
          distance_axis: data?.R_Axis0Dry_Refraction || null,
          distance_necylar: data?.R_NeCylar0Dry_Refraction || null,
          distance_sph: data?.R_Sph0Dry_Refraction || null,
          distance_vision: data?.R_Vision0Dry_Refraction || null,
          near_axis: data?.R_Axis1Dry_Refraction || null,
          near_necylar: data?.R_NeCylar1Dry_Refraction || null,
          near_sph: data?.R_Sph1Dry_Refraction || null,
          near_vision: data?.R_Vision1Dry_Refraction || null,
          va: data?.R_visual_acuity || null,
          auto_refraction: data?.R_auto_refraction || null,
          contrast_sensitivity: data?.R_contrast_sensitivity || null,
          refraction_dilated: data?.R_refraction || null,
          orthoptics: data?.R_orthoptics || null,
          glasses_prescription: data?.R_glasses_prescription === "yes" ? true : false,
          pgp: data?.R_pgp || null,
          intermediate_glasses_prescription: data?.R_intermediate_glasses_prescription || null,
          pmt: data?.R_pmt || null,
          retinoscopy: data?.R_retinoscopy || null,
          keratometry: data?.R_keratometry || null,
          contact_lens_prescription: data?.R_contact_lens_prescription || null,
          amsler: data?.R_amsler || null,
          color_vision: data?.R_color_vision || null,
          surgery_recommended:data?.surgery_recommended === "yes" ? true : false,
          others : data?.others || ""
        }
      ]
    }
    const action = edit ? 'editEyeExaminationDetails' : 'addEyeExaminationDetails';
    await commonApi(`api/${action}`, eyeExamination , {
      headers,
    })
    .then((data) => {
      if(data?.data || data?.data?.success == true){
        showToast("success", `${edit ? 'Updated' : 'Added'} Eye Examination`);
        getEyeExam();
      }
      setLoading(false)
    })
    .catch((err) => {
       showToast("error", "Something went wrong");
       setLoading(false)
    });
  };

  const discard = ()=>{
    fields.forEach(field => {
      setValue(field.name, '');
    });
    booleanFields.forEach(field => {
      setValue(field.name, 'no');
    });
  }
  const submitButtons = [
    {
      type: "primarySuccessButton",
      label: "Save Changes",
      class: "w-11rem pr-3",
      onClick: handleSubmit(onSubmit),
    },
    {
      type: "secondaryButton",
      label: "Discard",
      class: "w-7rem",
      onClick: () => {
        discard()
      },
    },
  ];

  const getEyeExam = async () =>{
    const data = { patient_visit_id: patientVisitId };
    try {
      const response = await commonApi("api/searchEyeExaminationDetails", data, { headers });
      const formData = response?.data?.data || null;
      if(Object.keys(formData).length !== 0){
        setEdit(true);
        const leftEyeData = formData.find(item => item.eye_type === "Left Eye");
        const rightEyeData = formData.find(item => item.eye_type === "Right Eye");
        fields.forEach(field => {
          let eyeData = field.name.startsWith('L_') ? leftEyeData : rightEyeData;
          let value = eyeData ? eyeData[field.index] : '';
          setValue(field.name, value || '');
        });
        booleanFields.forEach(field => {
          let eyeData = field.name.startsWith('L_') ? leftEyeData : rightEyeData;
          let value = '';
          if (eyeData[field.index]) {
            value='yes' 
          }else if(eyeData[field.index] != null && eyeData[field.index] === false){
            value='no'
          }
          setValue(field.name, value);
        });
      }else if(Object.keys(formData).length === 0){
        discard();
      }
    } catch (err) {
      showToast("error", "Something went wrong");
    }
  }

  const commonRender = (item, index) => {
    return (
      <InputHeaderWithWrap
        type={item?.type}
        header={item?.header}
        control={control}
        errors={errors}
        customClass={item?.customClass}
        formRender={item?.formRender}
        value={item?.value}
        onClick={(name, item) => {
          setValue(
            name,
            watch?.(name)?.filter((itemVal) => itemVal !== item)
          );
        }}
      />
    );
  };
  const renderInputHeader = (item, index) => {
    switch (item?.parentType) {
      case "plain":
        return commonRender(item, index);
      case "wrap":
        return (
          <div className="flex flex-wrap max-w-full gap-5">
            {item?.wrap?.map((item, index) => {
              return (
                <div className="flex-1 basis-auto">
                  {item?.items
                    ? item?.items?.map((item, index) => (
                        <div>{commonRender(item, index)}</div>
                      ))
                    : commonRender(item, index)}
                </div>
              );
            })}
          </div>
        );
      default:
        return <></>;
    }
  };

  const renderCall = (totalForm, index = null) => {
    let form = totalForm;
    if (totalForm[0]?.formName === 'optionalForm') {
      form = totalForm.map((formItem) => {
        return {
          ...formItem,
          wrap: formItem.wrap.map((wrapItem) => {
            return {
              ...wrapItem,
              items: wrapItem.items.map((item) => {
                return {
                  ...item,
                  formRender: item.formRender.map((input) => {
                    if (index === 0) {
                      return { ...input, name: `L_${input.name}` };
                    } else {
                      return { ...input, name: `R_${input.name}` };
                    }
                  }),
                };
              }),
            };
          }),
        };
      });
    }
    return form?.map((item, index) => {
      return renderInputHeader(item, index);
    });
  };
  return (
    <div className="Eye_exam">
      {renderCall(totalForm)}
      <div>
        {edit}
        <div>
          <RefractorTable register={register}/>
        </div>
        <div className="flex flex-wrap max-w-full gap-5 mt-4 totalInputPanel">
          {["More Options", "More Options"].map((ite,index) => (
            <div className="flex-1 basis-auto card-panel">
              <Panel
                ref={ref}
                header={ite}
                toggleable
                collapsed ={true}
                expandIcon = <i className="pi pi-chevron-right"/>
                collapseIcon = <i className="pi pi-chevron-down"/>
                className="primary-box-shadow"
              >
                {renderCall(optionalForm,index)}
              </Panel>
            </div>
          ))}
        </div>
      </div>
      {renderCall(surgeryCheck)}
      <div className="mt-6 ml-2">
        <AddButtonSection disabled={Object.keys(dirtyFields).length === 0 || loading} buttons={submitButtons} />
      </div>
      <DevTool control={control}/>
      <div className="split"></div>
    </div>
  );
}
