import React from "react";
import _ from "lodash";
import { visualHeading, visualTable } from "../../utils/serviceData";

export default function RefractorTable({ register, contentTable }) {
  contentTable = contentTable
    ? contentTable
    : [{ head: "Dry Refraction",eye:"L" }, { head: "Dry Refraction",eye:"R" }];
  const tableView = (head, body, child, prefix,eye = null) => {
    const tableKey = Object.keys(_.last(body));

    return (
      <div>
        {child && (
          <div className="public_sans_500 text-secondaryTextColor text-lg pb-2 py-3 border-primary-bottom pl-4">
            {child}
          </div>
        )}
        <div>
          <tr className="inter-600 text-sm text-white flex flex-wrap main-container border-primary-bottom">
            {head?.map((item, ind) => (
              <span
                className={`eye-table-heading h-3rem col-2 bg-black child-item heading ${
                  ind == 0 && "pl-4 justify-content-start"
                }`}
              >
                <span className="header-content public_sans_500">{item}</span>
              </span>
            ))}
          </tr>
        </div>
        <div>
          {body?.map((test, ind) => {
            let add = prefix ? ind + 'Dry_Refraction' : ind;
            return (
              <tr className="main-container border-primary-bottom">
                {tableKey.map((item) => {
                  if (item == "vp" || item == "Vision Type") {
                    return (
                      <td className="eye-table-body child-item value-container header pl-4">
                        <input
                          type="text"
                          disabled
                          value={test[item]?.name}
                          className="hide-border hide-outline"
                        />
                      </td>
                    );
                  } else {
                    return (
                      <td className="eye-table-body child-item value-container" style={{padding:"6px 12px 6px 6px"}}>
                        <input
                          type="text"
                          style={{border:"1px solid #2F2B3D1F", padding:"5px",borderRadius: "6px"}}
                          {...register(`${eye}_${test[item]?.name}${add}`)}
                          className="inter-500 color-grey-15 text-sm hide-border hide-outline "
                        />
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-wrap max-w-full gap-5">
      {contentTable?.map((item, ind) => (
        <div className="refraction-container flex-1 basis-auto">
          {tableView(visualHeading, visualTable, item.head, item.head,item.eye)}
        </div>
      ))}
    </div>
  );
}
