import React from "react";
import ButtonPrimaryComponent from "./buttonPrimaryComponents";

export default function AddButtonSection(props) {

  return (
    <React.Fragment>
      <div className="grid">
        {props?.buttons && props?.buttons.length > 0 && props?.buttons?.map((button, index) => {
          if (button?.type == "render") {
            return (
              <div className={button.class} key={index}>
                {button.render}
              </div>
            );
          } else {
            return (
              <div className={button.class} key={index}>
                <ButtonPrimaryComponent
                  icon={button.icon}
                  type={button.type}
                  label={button.label}
                  onClick={button.onClick}
                  spType={button.spType}
                  info={button.info}
                  watch={button.watch}
                  disabled={button.disabled || props?.disabled}
                  watchState={button.watchState}
                />
              </div>
            );
          }
        })}
      </div>
    </React.Fragment>
  );
}
