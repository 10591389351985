const Spec = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 3H4.5L2.25 10.5V12.375"
      stroke="#2F2B3D"
      stroke-opacity="0.9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 3H13.5L15.75 10.5V12.375"
      stroke="#2F2B3D"
      stroke-opacity="0.9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.5 12H10.5"
      stroke="#2F2B3D"
      stroke-opacity="0.9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="13.125"
      cy="12.375"
      r="2.625"
      stroke="#2F2B3D"
      stroke-opacity="0.9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="4.875"
      cy="12.375"
      r="2.625"
      stroke="#2F2B3D"
      stroke-opacity="0.9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Spec;
