import React, { useEffect, useState } from "react";
import InputIterator from "../../components/inputIterator";
import PrimarySideBar from "../../components/sideBar/primarySideBar";
import AddButtonSection from "../../components/AddButtonSection";
import { DevTool } from "@hookform/devtools";
import { useForm } from "react-hook-form";
import { patientFormRender as formRender } from "../../utils/serviceData";
import CapturePhoto from "../../components/capturePhoto/capturePhoto";
import moment from "moment";
import Util, {
  generateValidObject,
  setVal,
  dateFormat,
} from "../../utils/utils";
import { commonApi, patientCreateOrUpdate } from "../../utils/apiService";
import Loader from "../../commonComponents/loader";
import { showToast } from "../../commonComponents/toast";

export default function AddOrEditPatient({
  formName = "editProfile",
  ...props
}) {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoader] = useState(false);
  const [uploadedImg, setUploadedImg] = useState({});
  const [serverLoading, setServerLoading] = useState(null);

  const onImageCapture = (param) => {
    let capturedFile = Util.dataURLtoFile(
      param,
      `image-capture${moment().format("YYYYMMDDHHmmssa")}.jpg`
    );
    setUploadedImg(capturedFile);
    capturedFile &&
      capturedFile.size > 0 &&
      setValue("profile_picture", capturedFile?.name);
  };

  const onSubmit = async (data) => {
    if (serverLoading) return;
    setServerLoading(true);
    let validObj = generateValidObject(data);
    const dob = dateFormat(validObj["dob"]);
    validObj = {
      ...validObj,
      dob: dob,
    };
    if (
      Object.entries(dirtyFields).length > 0 ||
      (uploadedImg && uploadedImg.size && uploadedImg.size > 0)
    ) {
      let finalData = validObj;
      const newObj = {};
      Object.keys(dirtyFields).forEach((key) => {
        formRender.forEach((item) => {
          if (item.name == key) {
            newObj[item.fieldLabel] = validObj[key];
          } else if (item?.item) {
            item?.item?.forEach((item) => {
              if (item.name == key) {
                newObj[item.fieldLabel] = validObj[key];
              }
            });
          }
        });
      });
      if (formName == "editProfile") newObj["patient_id"] = props?.id;
      finalData = newObj;
      let headers;
      if (uploadedImg && uploadedImg.size && uploadedImg.size > 0) {
        finalData = new FormData();
        finalData.append("profile_picture", uploadedImg);
        finalData.append("_payload", JSON.stringify(newObj));
        headers = {
          "Content-Type": "multipart/form-data",
        };
      } else {
        headers = {
          "Content-Type": "application/json",
        };
      }
      await patientCreateOrUpdate("api/editpatientdetails", finalData, {
        headers,
      })
        .then((data) => {
          showToast("success", "Patient Updated Successfully");
          props?.setVisible?.(false, true);
          props?.initCallBack?.();
          reset();
          setUploadedImg({});
          setServerLoading(false);
        })
        .catch((err) => {
          showToast("error", "Something went wrong");
          setServerLoading(false);
        });
    }
    else{
      setServerLoading(false)
    }
  };

  const callData = async () => {
    setLoader(true);
    await commonApi("/api/searchpatientdetails", {
      patient_id: props?.id,
    }).then((data) => {
      const patientDetails = data?.data?.patient;
      dataUpdate({ originalData: patientDetails });
      setLoader(false);
    });
  };

  var buttons = [
    {
      spType: "file",
      type: "primaryButton",
      label: "Edit Profile Picture",
      class: "col-9",
      icon: "pi pi-pencil",
      info: uploadedImg?.name,
      watch: watch && watch,
      watchState: "profile_picture",
      onClick: (e) => {
        e?.target?.files?.[0] && setUploadedImg(e?.target?.files?.[0]);
        setValue("profile_picture", e?.target?.files?.[0]?.name);
      },
    },
    {
      type: "secondaryButton",
      label: "Reset",
      class: "col-3",
      onClick: () => {
        setUploadedImg({});
        setValue("profile_picture", "");
      },
    },
    {
      type: "render",
      render: (
        <div className="msgText font-normal text-sm p-2 pb-3">
          Allowed JPG, GIF or PNG. Max size of 800K
        </div>
      ),
    },
    {
      type: "render",
      render: (
        <CapturePhoto
          imgWidth={200}
          imgHeight={150}
          webcamWidth={410}
          webcamHeight={320}
          onImageCapture={onImageCapture}
        />
      ),
    },
    {
      type: "primarySuccessButton",
      label: "Save Changes",
      class: "col-6",
      onClick: handleSubmit(onSubmit),
    },
    {
      type: "secondaryButton",
      label: "Discard",
      class: "col-4",
      onClick: () => {
        props?.setVisible?.(false);
      },
    },
  ];

  const dataUpdate = (patient) => {
    setVal(formRender, patient?.originalData, (key, value) => {
      setValue(key, value);
    });
    setValue("profile_picture", patient?.originalData?.profile_picture);
  };

  useEffect(() => {
    setVisible(props?.visible);
  }, [props?.visible]);

  useEffect(() => {
    props?.profile == "patient" && props?.id && callData(props?.setVisible);
  }, []);

  return (
    <React.Fragment>
      <React.Fragment>
        <PrimarySideBar
          visible={visible}
          onHide={() => props?.setVisible?.(false)}
          header={<div className="sideBar-header">Edit Patient</div>}
        >
          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <InputIterator
                control={control}
                errors={errors}
                formRender={formRender}
              />

              <div className="pt-3">
                <AddButtonSection disabled={serverLoading} buttons={buttons} />
              </div>
            </React.Fragment>
          )}
        </PrimarySideBar>
        <DevTool control={control} />
      </React.Fragment>
    </React.Fragment>
  );
}
