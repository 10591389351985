import { axiosInstance } from "./appService";

export function login(payload) {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("/login", {
        ...payload,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function patientList(payload) {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("/api/patientdetails", payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getCampDetails() {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get("/api/campdetails")
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function commonApi(api, payload, headers) {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(api, payload, headers)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function commonGetApi(api) {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(api)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function patientCreateOrUpdate(api, payload, headers) {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(api, payload, headers)
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          resolve(data?.data?.data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
