import React, { useState, useRef, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Outlet } from "react-router-dom";
import UserImg from "../assets/images/user.svg";
import DashboardImg from "../assets/images/dashboard.svg";
import InvoicesImg from "../assets/images/invoice.svg";
import ReportsImg from "../assets/images/reports.svg";
import LogisticsImg from "../assets/images/logistics.svg";
import SettingsImg from "../assets/images/settings.svg";
import RotaryClubLogo from "../assets/images/rotary-club-sub-logo.svg";
import Search from "../components/Search";
import SidebarMenu from "../components/sidebarMenu";
import "./Layout.scss";

const Layout = () => {
  const elementRef = useRef(null);
  const navigate = useNavigate();
  const [hasVerticalScroll, setHasVerticalScroll] = useState(false);
  const toast = useRef(null);
  const location = useLocation();
  const items = [
    {
      label: "Dashboard",
      icon: "icon-dashboard",
      command: () => {
        navigate("/dashboard");
      },
    },
    {
      label: "Patients",
      icon: "icon-human",
      command: () => {
        navigate("/patients");
      },
    },
    {
      label: "Logistics",
      icon: "icon-start",
      command: () => {
        navigate("/logistics");
      },
    },
    {
      label: "Invoices",
      icon: "icon-paper",
      command: () => {
        navigate("/invoices");
      },
    },
    {
      label: "Reports",
      icon: "icon-graph",
      command: () => {
        navigate("/reports");
      },
    },

    {
      label: "Settings",
      icon: "icon-settings",

      items: [
        {
          label: "Users",
          icon: "icon-Oval",

          command: () => {
            navigate("/users");
          },
        },
        // {
        //   label: "Camps",
        //   icon: "pi pi-circle",
        //   command: () => {
        //     navigate("/camps");
        //   },
        // },
        // {
        //   label: "Organizations",
        //   icon: "pi pi-circle",
        //   command: () => {
        //     navigate("/organizations");
        //   },
        // },
        // {
        //   label: "Roles & Permission",
        //   icon: "pi pi-circle",
        //   command: () => {
        //     navigate("/roles");
        //   },
        // },
      ],
    },
  ];

  const checkScroll = useCallback(() => {
    const element = elementRef.current;
    if (element) {
      setHasVerticalScroll(element.scrollHeight > element.clientHeight);
    }
  }, []);

  useEffect(() => {
    const delayedCheck = setTimeout(() => checkScroll(), 100);
    return () => clearTimeout(delayedCheck);
  }, [location, checkScroll]);

  useEffect(() => {
    const handleResize = () => requestAnimationFrame(checkScroll);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [checkScroll]);

  useEffect(() => {
    const observer = new MutationObserver(() => checkScroll());
    const currentElement = elementRef.current;
    if (currentElement) {
      observer.observe(currentElement, { childList: true, subtree: true });
    }
    return () => observer.disconnect();
  }, [checkScroll]);

  return (
    <div className="Layout__main-container">
      <div className="Layout__inner-container">
        <div className="Layout__left-sec">
          <header>
            <div className="Layout__left-header-logo-sec">
              <img src={RotaryClubLogo} alt="logo" />
            </div>
            <div className="px-2">
              <SidebarMenu visible={true} menuItems={items} />
            </div>
            <Toast ref={toast} />
          </header>
        </div>
        <div className="Layout__right-container">
          <div className={hasVerticalScroll ? "pr-5" : "pr-3"}>
            <div className="Patient__search-container pt-4">
              <Search />
            </div>
          </div>
          <main className="pr-3 overflow-x-hidden" ref={elementRef}>
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
