import { SelectButton } from "primereact/selectbutton";
import "../assets/styles/selectButton.scss";
import React from "react";

export default function SelectOption({
  value,
  setValue = () => {},
  setShow,
  optionLabel = "value",
  itemTemplate = (option) => (
    <React.Fragment>
      {option.icon && <i className={option.icon} />}
      <span>{option.name}</span>
    </React.Fragment>
  ),
  options,
  ...props
}) {
  const tab=(data)=>{
    if(data === 4){
      setShow(true)
    }
  }
  return (
    <div className={`select-button ${props?.className}`}>
      <SelectButton
        value={value}
        onChange={(e) => {setValue(e.value);tab(e.value)}}
        itemTemplate={itemTemplate}
        optionLabel={optionLabel}
        options={options}
      />
    </div>
  );
}
