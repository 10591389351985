import React from "react";
import { commonApi } from "../../utils/apiService";
import CommonModal from "../../components/commonModel";
import AddButtonSection from "../../components/AddButtonSection";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../commonComponents/toast";

export default function DeletePatient({ visible, setVisible, ...props }) {
  const navigate = useNavigate();
  const deletePatient = async () => {
    await commonApi("/api/deletepatientdetails", {
      patient_id: props?.patient?.patient_id,
    }).then((data) => {
      if(data?.status == 200){
        showToast("success", "Patient deleted Successfully");
        setVisible?.(false, true);
        !props.patientPage && navigate("/patients");
      } else {
        showToast("error", "Something went wrong");
      }
    });
  };

  const submitButtons = [
    {
      type: "primarySuccessButton",
      label: "Yes",
      class: "w-8rem pr-3",
      onClick: () => {
        deletePatient()
      },
    },
    {
      type: "secondaryButton",
      label: "No",
      class: "w-7rem",
      onClick: () => {
        setVisible?.(false);
      },
    },
  ];

  return (
    <div>
      <CommonModal
        visible={visible}
        header="Delete Patient"
        onHide={() => setVisible(false)}
      >
        <div>
          <div className="flex justify-content-center pr-4">
            Would you like to delete this patient?
          </div>

          <div className="mt-6 ml-2">
            <AddButtonSection buttons={submitButtons} />
          </div>
        </div>
      </CommonModal>
    </div>
  );
}
