import React, { useState, useRef, useEffect } from "react";
import Search from "../../components/Search";
import "../../assets/styles/Visits.scss";
import { Button } from "primereact/button";
import DataGrid from "../../commonComponents/dataGrid/dataGrid";
import {
  arrageArrayFunc,
  convertToNameLabel,
  dataToNameAndLabel,
  convetToSearchField,
  mask,
} from "../../utils/utils";
import ButtonWithSearch from "../../components/buttonWithSearch";
import { useParams } from "react-router-dom";
import { commonApi } from "../../utils/apiService";
import ProfileView from "../../components/profileView";
import { nonRequireVisitListField } from "../../utils/serviceData";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import ListTabView from "../../commonComponents/tabView";
import TabHeaderView from "../../components/tabHeaderView";
import ClinicalInformation from "../examination/clinicalInformation";
import EyeExam from "../examination/eyeExam";
import Treatement from "../examination/treatement";
import AddOrEditPatient from "../patients/addOrEditPatient";
import AddVisits from "./addVisits";
import _ from "lodash";
import DeletePatient from "../patients/deletePatient";
import PostSurgeryFollowUp from "../examination/postSurgeryFollowUp";
import PaymentDetails from "../payment/paymentDetails";
import Path from "../../assets/images/Path";
import Spec from "../../assets/images/spec";
import Prescription from "../../assets/images/prescription";
import Surgery from "../../assets/images/surgery";
import Payment from "../../assets/images/payment";

const Visits = () => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({});
  const menuLeft = useRef(null);
  const [products, setProducts] = useState([]);
  const [values, setValues] = useState([]);
  const [patient, setPatient] = useState({});
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [examination, setExamination] = useState({});
  const [profileLoading, setProfileLoading] = useState({});
  const [visible, setVisible] = useState({
    editPatient: false,
    addVisit: false,
  });
  const searchFields = [
    "visit_date",
    "patient_visit_code",
    "examiner_name",
    "examined_by",
    "purpose",
    "status",
  ];
  const { id } = useParams();
  const initialVal = convetToSearchField(searchFields, "");
  const [navigator, setNavigator] = useState({
    page: 1,
    first: 0,
    rows: 10,
    totalRecords: 1,
    sortBy: "patient_visit_code",
    sortDir: "asc",
    searchCriteria: {
      patient_id: id,
      ...initialVal,
    },
  });

  function onPageChange(event) {
    setLoading(true);
    setNavigator((prev) => ({
      ...prev,
      first: event?.first,
      page: event.page + 1,
    }));
    init({
      ...navigator,
      first: event?.first,
      page: event.page + 1,
    });
  }

  const onRowCountChange = (e) => {
    setLoading(true);
    setNavigator((prev) => ({
      ...prev,
      rows: e?.target?.value,
      page: 1,
      first: 0,
    }));
    init({
      ...navigator,
      page: 1,
      first: 0,
      rows: e?.target?.value,
    });
  };

  const onRowClick = (event) => {
    setExamination({ id: event?.data?.patient_visit_id, view: true });
  };

  const init = async (navigator, refresh) => {
    setInitLoading(true);
    !refresh && setProfileLoading(true);
    let currentObj = {
      query: {
        page: navigator?.page,
        limit: navigator?.rows,
        sortFields: [[navigator?.sortBy, navigator?.sortDir]],
        searchCriteria: navigator?.searchCriteria,
      },
    };

    Promise.all([
      await commonApi("/api/searchlookupdetails", {
        lookup_type_name: "VISIT_PURPOSE",
      }),
      await commonApi("/api/searchpatientdetails", {
        patient_id: id,
      }),
      await commonApi("/api/searchpatientvisitdetails", currentObj),
    ])
      .then(async ([lookupdetails = [], data = {}, visitData = []]) => {
        const lookup = lookupdetails?.data?.data;
        let visitLookUp = visitData?.data?.data;
        const visitDetails = visitLookUp.map((visit) => {
          const matchingData = lookup?.find(
            (item) => item.lookup_id === visit.purpose
          );
          return {
            ...visit,
            purpose: matchingData ? matchingData.name : null,
          };
        });
        setNavigator((prev) => ({
          ...prev,
          totalRecords: visitData?.data?.total,
          page: visitData?.data?.currentPage,
        }));

        const patientDetails = data?.data?.patient;
        patientDetails.aarogyasri_no = patientDetails["health_insurance_card"];
        patientDetails.aadhaar_no = patientDetails["aadhar_no"];
        const finalList = {};
        [
          "patient_code",
          "gender",
          "dob",
          "aadhaar_no",
          "aarogyasri_no",
          "ration_card_no",
          "address",
        ].forEach((item) => {
          if (item === "patient_code") {
            finalList["patient_iD"] = patientDetails[item];
          } else {
            finalList[item] = patientDetails[item];
          }
        });

        let finalData = convertToNameLabel(finalList, [
          "patient_name",
          "first_name",
          "last_name",
          "profile_picture",
          "district",
          "state",
          "city",
          "pincode",
          "phone_no",
          "updated_by",
          "created_at",
          "created_by",
          "updated_at",
        ]);
        finalData.name = patientDetails["patient_name"];
        finalData.imgUrl = patientDetails?.profile_picture
          ? `${process.env?.REACT_APP_CURRENT_URL}api/searchpatientdetails/${patientDetails?.patient_id}`
          : null;
        finalData = Object.entries(finalData).map(([label, value]) => {
          if (
            value?.value &&
            ["aadhaar_no", "aarogyasri_no", "ration_card_no"].includes(label)
          ) {
            return { [label]: { ...value, value: mask(value?.value) } };
          } else {
            return { [label]: value };
          }
        })?.reduce((acc, item) => {
          // Check if the item is an array with a single object inside
          const normalizedItem = Array.isArray(item) && item.length === 1 ? item[0] : item;
          return { ...acc, ...normalizedItem };
      }, {});

      setPatient({ finalData, originalData: patientDetails });

        setProducts(
          arrageArrayFunc(
            dataToNameAndLabel(
              visitDetails,
              null,
              null,
              nonRequireVisitListField,
              null,
              {
                visit_date: "date",
                patient_visit_code: "visit_id",
                examiner_name: "examiner",
                examiner_code: "examiner_id",
                purpose: "type",
              }
            ),
            [
              "visit_date",
              "patient_visit_code",
              "examiner_name",
              "examiner_code",
              "purpose",
              "status",
            ]
          )
        );
        setValues(visitDetails);
        setLoading(false);
        setInitLoading(false);
        setProfileLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSortClick = (event) => {
    setLoading(true);
    setNavigator((prev) => ({
      ...prev,
      sortBy: event?.sortField,
      sortDir: event?.sortOrder == 1 ? "asc" : "desc",
      sortFields: [[event?.sortField, event?.sortOrder == 1 ? "ASC" : "DESC"]],
    }));
    init({
      ...navigator,
      sortBy: event?.sortField,
      sortDir: event?.sortOrder == 1 ? "asc" : "desc",
      sortFields: [[event?.sortField, event?.sortOrder == 1 ? "ASC" : "DESC"]],
    });
  };

  function handleSearchChange(value) {
    const data = convetToSearchField(searchFields, value);
    setNavigator((prev) => ({
      ...prev,
      searchCriteria: {
        ...data,
        patient_id: id,
      },
    }));
    init({
      ...navigator,
      searchCriteria: {
        ...data,
        patient_id: id,
      },
    });
  }

  const button = [
    {
      type: "primarySuccessButton",
      label: "Add Visit",
      icon: "pi pi-plus",
      class: "mt-2 w-full flex pr-3",
      onClick: () => {
        setVisible((prev) => ({
          ...prev,
          addVisit: true,
        }));
      },
    },
  ];
  const itemRenderer = (item) => (
    <div className="p-menuitem-content">
      {item.label === "Add visit" && (
        <Button
          className="card-button-add"
          icon="pi pi-plus"
          label="Add Visit"
        />
      )}
      {item.label === "Edit" && (
        <Button className="card-button-edit" label="Edit" />
      )}
      {item.label === "Export" && (
        <Button className="card-button-edit" label="Export" />
      )}
    </div>
  );
  const buttonEdit = [
    {
      type: "primarySuccessButton",
      label: "Edit",
      class: "card-button-edit",
      onClick: () => {
        setVisible((prev) => ({ ...prev, editPatient: true }));
      },
    },
    // {
    //   type: "primaryCancelButton",
    //   label: "Delete",
    //   class: "card-button-delete",
    //   onClick: () => {
    //     setVisible((prev) => ({ ...prev, deletePatient: true }));
    //   },
    // },
  ];
  useEffect(() => {
    init(navigator);
  }, []);

  return (
    <div className="Visits__main-container">
      {/* <div className="Visits__search-container">
        <Search />
      </div> */}
      <ProfileView
        profile={patient?.finalData}
        button={buttonEdit}
        loading={profileLoading}
        parentClass={"ml-auto"}
      />
      <DataGrid
        header={
          <ButtonWithSearch
            buttons={button}
            searchHeaderLable={"Visits"}
            isSearchEnabled={true}
            placeholder={"Search Visits"}
            isCalenderEnabled={false}
            isButtonEnabledLeft={false}
            isButtonEnabledRight={true}
            searchPlaceHolder={"Search visits"}
            loading={initLoading}
            styles={{ gridTemplateColumns: "1fr 400px" }}
            onChange={handleSearchChange}
          />
        }
        onSortClick={onSortClick}
        onRowCountChange={onRowCountChange}
        onRowClick={onRowClick}
        onPageChange={onPageChange}
        isPaginatorEnabled={true}
        navigator={navigator}
        column={products}
        value={values}
        ref={menuLeft}
        loading={loading}
        sortable={
          (values && !_.isArray(values)) || (values && values?.length !== 0)
        }
      />
      {visible?.addVisit && (
        <AddVisits
          patientId={id}
          visible={visible?.addVisit}
          setVisible={(bool) => {
            setVisible((prev) => ({ ...prev, addVisit: bool }));
            init(navigator, true);
          }}
        />
      )}
      {visible?.deletePatient && (
        <DeletePatient
          visible={visible?.deletePatient}
          patient={patient?.originalData}
          setVisible={(bool) =>
            setVisible((prev) => ({ ...prev, deletePatient: bool }))
          }
        />
      )}
      {visible?.editPatient && (
        <AddOrEditPatient
          patient={patient}
          visible={visible?.editPatient}
          profile="patient"
          id={id}
          setVisible={(bool, refresh) => {
            setVisible((prev) => ({ ...prev, editPatient: bool }));
            if (refresh) init(navigator);
          }}
        />
      )}
      <DevTool control={control} />
      {examination?.id && examination?.view && (
        <ListTabView
          index={4}
          tabClass={"payement-details-tab"}
          defaultIndex={0}
          listComponent={[
            {
              header: (
                <TabHeaderView
                  iconHtml={
                    <span className="pr-2">
                      <Path />
                    </span>
                  }
                  title={"Clinical Information"}
                />
              ),
              content: <ClinicalInformation patientVisitId={examination?.id} />,
            },
            {
              header: (
                <TabHeaderView
                  iconHtml={
                    <span className="pr-2">
                      <Spec />
                    </span>
                  }
                  title={"Eye Exam"}
                />
              ),
              content: <EyeExam patientVisitId={examination?.id} />,
            },
            {
              header: (
                <TabHeaderView
                  iconHtml={
                    <span className="pr-2">
                      <Prescription />
                    </span>
                  }
                  title={"Treatment"}
                />
              ),
              content: <Treatement patientVisitId={examination?.id} />,
            },
            {
              header: (
                <TabHeaderView
                  iconHtml={
                    <span className="pr-2">
                      <Surgery />
                    </span>
                  }
                  title={"Post Surgery Follow-Up"}
                />
              ),
              content: <PostSurgeryFollowUp patientVisitId={examination?.id} />,
            },
            {
              header: (
                <TabHeaderView
                  iconHtml={
                    <span className="pr-2">
                      <Payment />
                    </span>
                  }
                  title={"Payment Details"}
                />
              ),
              content: (
                <PaymentDetails
                  patientVisitId={examination?.id}
                  patientId={id}
                />
              ),
            },
          ]}
        />
      )}
    </div>
  );
};
export default Visits;
