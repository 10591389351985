import React from "react";

export default function TabHeaderView({
  icon,
  iconHtml,
  title,
  stdClass = "pr-2",
}) {
  return (
    <div className="flex align-items-center">
      {iconHtml && iconHtml}
      {icon && <i className={icon + " " + stdClass}></i>}
      <span>{title}</span>
    </div>
  );
}
