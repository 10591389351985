import { Sidebar } from "primereact/sidebar";
import React, { Children,useState } from "react";
import "../assets/styles/sidebar.scss";

export default function SideBar({
  visible = false,
  onHide = () => {},
  position = "right",
  children,
  ...props
}) {
  return (
    <div className="sidebar-container">
      <Sidebar className={`sidebar-custom ${props?.className}`} visible={visible} position={position} onHide={onHide} {...props.customProp}>
        {children}
      </Sidebar>
    </div>
  );
}
