import React, { useEffect, useState } from "react";
import ClockImg from "../assets/images/clock.svg";
import ArrivedImg from "../assets/images/car.svg";
import MissedImg from "../assets/images/square-x.svg";
import PlusImg from "../assets/images/plus.svg";
import UpImg from "../assets/images/up.svg";
import PickupImg from "../assets/images/pickup.svg";
import Chart from "react-apexcharts";
import { commonApi } from "../utils/apiService";
import Loader from "../commonComponents/loader";
import DateSelect from "../components/dateSelect";
import LineChart from "../components/lineChart";
import { ProgressBar } from "primereact/progressbar";
import "./Dashboard.scss";
import VerticalBarChart from "../components/verticalBarChart";
import { financial } from "../utils/static";
import moment from "moment";

const Dashboard = () => {
  const [graphData, setGraphData] = useState({
    financial: financial,
  });
  const [show, setShow] = useState(false);
  const [date, setDate] = useState([]);
  const [selectOption, setSelectOption] = useState(1);
  const [rangeDetails, setRangeDetails] = useState("Today");
  const imgSources = {
    scheduled: ClockImg,
    arrived: ArrivedImg,
    missed: MissedImg,
  };
  const iconMapping = {
    new: PlusImg,
    follow_up: UpImg,
    pick_up: PickupImg,
  };

  const progressColor = {
    new: "rgba(40, 44, 135, 1)",
    follow_up: "rgba(0, 186, 209, 1)",
    pick_up: "rgba(255, 76, 81, 1)",
  };

  const fetchDashboardData = async (payload = { filterType: "today" }) => {
    try {
      await Promise.all([
        commonApi("api/patienttransportationchart", payload),
        commonApi("api/treatmentbreakdown", payload),
        commonApi("api/dashboardappoitments", payload),
        commonApi("api/patientfinancialinformation", payload),
      ]).then(
        ([
          patientTransportation,
          treatmentRecommended,
          appointment,
          financial,
        ]) => {
          const totalGraph = {};
          const appoints = appointment?.data?.data;
          const treatment = treatmentRecommended?.data?.data;
          totalGraph.transportation = patientTransportation?.data?.data;
          totalGraph.financial = {
            ...graphData.financial,
            series: financial?.data?.data?.[0]?.map((item) => item?.count),
          };
          totalGraph.appointment = {
            totalAppointment: appointment?.data?.total_count,
            labels: appoints?.map((item) => item?.period),
            data: appoints?.map((item) => item?.visit_count),
            visitDetails: [
              {
                purpose: "New",
                nativeName: "new",
                visit_count: appointment?.data?.new,
              },
              {
                purpose: "Follow Up",
                nativeName: "follow_up",
                visit_count: appointment?.data?.follow_up,
              },
              {
                purpose: "Pick Up",
                nativeName: "pick_up",
                visit_count: appointment?.data?.pick_up,
              },
            ],
          };
          const treatmentData = {
            categories: [],
            percentage: [],
            series: [
              {
                data: [],
              },
            ],
          };
          treatment.forEach(({ count, percentage, status }, ind) => {
            treatmentData.categories.push(status);
            treatmentData.percentage.push(Number(percentage));
            treatmentData.series[0].data.push(count);
          });
          totalGraph.treatment = treatmentData;
          setGraphData(totalGraph);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const rangeDetail = {
    1: "Today",
    2: "Last 7 Days",
    3: "Last 30 Days",
    4:
      moment(date?.[0]?.startDate).format("DD/MM/YY") +
      "-" +
      moment(date?.[0]?.endDate).format("DD/MM/YY"),
  };

  useEffect(() => {
    setRangeDetails(rangeDetail[selectOption]);
  }, [date, selectOption]);
  const generatePayload = (date1, date2, selectedOption) => {
    const filterType = {
      1: "today",
      2: "week",
      3: "month",
      4: "custom",
    };
    const payload = {};
    if (selectedOption === 4) {
      payload.startDate = date1;
      payload.endDate = date2;
    }
    payload.filterType = filterType[selectedOption];
    fetchDashboardData(payload);
  };

  return (
    <div className="Dashboard__main-container">
      <div className="pt-4">
        <DateSelect
          show={show}
          setShow={setShow}
          patientDashboard={(date1, date2, selectedOption) => {
            generatePayload(date1, date2, selectedOption);
          }}
          setDashboardDate={setDate}
          selectOption={selectOption}
          setSelectOption={setSelectOption}
        />
      </div>
      <div className="Dashboard__inner-container">
        <div className="Dashboard__apts-trans-card-sec">
          <div className="Dashboard__apts-trans-item">
            {graphData?.appointment?.totalAppointment ||
            graphData?.appointment?.totalAppointment === 0 ? (
              <>
                <div className="Dashboard__apts-trans-header">
                  <div className="Dashboard__apt-trans-left-sec">
                    <p className="Dashboard__card-heading">Appointments</p>
                    <span className="Dashboard__card-sub-heading">
                      Overview
                    </span>
                  </div>
                </div>
                <div className="Dashboard__apts-trans-info-sec">
                  <div className="Dashboard__apts-trans-info-left">
                    <p className="total-count">
                      {graphData?.appointment?.totalAppointment}{" "}
                      {/* <span className="progress-lbl up-color">
                        {graphData?.appointment?.percentage_change
                          ? Math.round(
                              graphData?.appointment?.percentage_change * 100
                            ) / 100
                          : 0}
                        %
                      </span> */}
                    </p>
                    <span className="apts-lbl">
                      Total Appointments {rangeDetails}
                    </span>
                  </div>
                  <div className="Dashboard__apts-trans-info-right">
                      <LineChart graphData={graphData?.appointment} />
                  </div>
                </div>
                <div className="Dashboard__apts-trans-footer-sec">
                  <div className="Dashboard__apts-trans-footer-list">
                    {graphData?.appointment?.visitDetails?.map(
                      (item, index) => (
                        <div className="apts-trans-footer-item" key={index}>
                          <p className="apt-type mb-2">
                            <span>
                              <img src={iconMapping[item?.nativeName]} alt="" />
                            </span>
                            <span className="apt-type-lbl">
                              {item?.purpose}
                            </span>
                          </p>
                          <div>
                            <span className="apt-count">
                              {item?.visit_count}
                            </span>
                            <div className="card pt-2">
                              <ProgressBar
                                value={
                                  (item?.visit_count /
                                    graphData?.appointment?.totalAppointment) *
                                    100 || 0
                                }
                                color={
                                  progressColor[item?.nativeName] ||
                                  "rgba(40, 44, 135)"
                                }
                                style={{
                                  height: "4px",
                                  backgroundColor: "rgba(47, 43, 61, 0.08)",
                                }}
                              ></ProgressBar>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </>
            ) : (
              <Loader />
            )}
          </div>
          <div className="Dashboard__apts-trans-item">
            {graphData?.transportation?.length === 0 ? (
              <Loader />
            ) : (
              <>
                <div className="Dashboard__apts-trans-header">
                  <div className="Dashboard__apt-trans-left-sec">
                    <p className="Dashboard__card-heading">
                      Patient Transportation
                    </p>
                  </div>
                </div>
                <div className="Dashboard__pat-trans-info-sec">
                  <div className="Dashboard__transport-graph-sec">
                    {graphData?.transportation?.map((item, index) => (
                      <div
                        key={index}
                        style={{ width: `${item?.percentage}` }}
                        className="Dashboard__transport-graph-item"
                      >
                        <div
                          className={`transport-bg-${item?.status?.toLowerCase()} trans-value-sec`}
                        >
                          {item?.percentage}
                        </div>
                      </div>
                    ))}
                  </div>
                  <ul className="Dashboard__pat-trans-list">
                    {graphData?.transportation?.map((item, index) => (
                      <li key={index}>
                        <p className="trans-name">
                          <span className="trans-icon">
                            <img
                              src={imgSources[item?.status]}
                              alt={item?.status}
                            />
                          </span>
                          <span
                            className={`name-lbl name-lbl-${item?.status?.toLowerCase()}`}
                          >
                            {item?.status}
                          </span>
                        </p>
                        <p className="trans-values">
                          <span className="val-lbl">{item?.count}</span>
                          <span className="percentage-lbl">
                            {item?.percentage}
                          </span>
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="Dashboard__invoice-card-sec">
          <div className="Dashboard__invoice-card-item">
            <div className="Dashboard__apts-trans-header">
              <div className="Dashboard__apt-trans-left-sec">
                <p className="Dashboard__card-heading">Treatment Breakdown</p>
              </div>
            </div>
            <div className="Dashboard__apts-trans-header">
              <div className="Dashboard__apt-trans-left-sec mt-4">
                <VerticalBarChart graphData={graphData?.treatment} />
              </div>
            </div>
          </div>
          <div className="Dashboard__invoice-card-item">
            <div className="Dashboard__apts-trans-header">
              <div className="Dashboard__apt-trans-left-sec">
                <p className="Dashboard__card-heading">Treatment</p>
              </div>
            </div>
            <div className="Dashboard__treatment-sec">
              {graphData?.financial?.series.length === 0 ? (
                <Loader />
              ) : (
                <>
                  <div>
                    <Chart
                      options={graphData?.financial?.options}
                      series={graphData?.financial?.series}
                      type="donut"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
