import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import AddButtonSection from "../../components/AddButtonSection";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import ButtonWithSearch from "../../components/buttonWithSearch";
import arrived from '../../assets/images/arrived.svg'

export default function DataGridHeader({
  handleClick,
  isSearchEnabled,
  secoundaryButton,
  isCalenderEnabled,
  searchPlaceHolder,
  isButtonEnabledLeft,
  checkBox,
  isButtonEnabledRight,
  currentPageHeader,
  handleDateChange,
  ...props
}) {
  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [
    {
      name: "New York",
      value: "NY",
    },
    {
      name: "New York",
      value: "NY",
    },
    {
      name: "New York",
      value: "NY",
    },
  ];
  const displayButtonLabelName = (pageHeader) => {
    switch (pageHeader) {
      case "Patient List":
        return "Add Patient";
      case "Invoices List":
        return "Add Invoice";
      case "Arrived":
        return "Arrived";
      case "Schedule":
        return "Schedule New Appointment";
      case "Patient List":
        return "Add Patient";
      case "Invoice List":
        return "Add Invoice";
      default:
        return "";
    }
  };
  const displayIconLabelName = (pageHeader) => {
    switch (pageHeader) {
      case "Patient List":
        return "pi pi-plus";
      case "Invoices List":
        return "pi pi-plus";
      case "Arrived":
        return <img  className="pr-2 -ml-1"src={arrived}/>;
      case "Invoice List":
        return "pi pi-plus";
        case "Schedule":
          return "pi pi-calendar-plus";
      default:
        return "";
    }
  };
  const button = [
    {
      type: "primarySuccessButton",
      label: displayButtonLabelName(currentPageHeader),
      icon: displayIconLabelName(currentPageHeader),
      disabled:checkBox?.length === 0,
      class: "mt-2 w-full flex pr-3",
      onClick: () => {
        handleClick("addPatient", currentPageHeader);
      },
    },
  ];

  return (
    <div>
      <div>
        <div className="px-3 layout-none">
          <div className="grid">
            <div className="col-4">
              <div className="InputPrimaryComponents custom-input-component">
                <Dropdown
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.value)}
                  options={cities}
                  optionLabel="name"
                  editable
                  placeholder="Select a City"
                  className="w-full h-inherit"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="InputPrimaryComponents custom-input-component">
                <Dropdown
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.value)}
                  options={cities}
                  optionLabel="name"
                  editable
                  placeholder="Select a City"
                  className="w-full"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="InputPrimaryComponents custom-input-component">
                <Dropdown
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.value)}
                  options={cities}
                  optionLabel="name"
                  editable
                  placeholder="Select a City"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ButtonWithSearch
        searchHeaderLable={currentPageHeader}
        buttons={button}
        secoundaryButton={secoundaryButton}
        currentPageHeader={currentPageHeader}
        isSearchEnabled={isSearchEnabled}
        handleDateChange={handleDateChange}
        isCalenderEnabled={isCalenderEnabled}
        isButtonEnabledLeft={isButtonEnabledLeft}
        isButtonEnabledRight={isButtonEnabledRight}
        searchPlaceHolder={searchPlaceHolder}
        styles={{ gridTemplateColumns: "1fr 420px" }}
        {...props}
      />
    </div>
  );
}
