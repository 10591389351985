import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import _ from "lodash";
import React from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

export function className(type, ind) {
  switch (type) {
    case "heading":
      return "tableHeader";

    case "bigBold":
      if (ind == 0) {
        return "bodyText textBold pl-20p";
      } else return "bodyText normalBody";

    default:
      return "";
  }
}

export function generateColumnContent(
  e,
  item,
  concernId,
  columnClickable,
  urlPath,
  detailUrlPrefix
) {
  let detailsPath = concernId ? e?.[concernId] : "";
  if (columnClickable && item.name === columnClickable) {
    return (
      <Link className="cellclickable" to={urlPath + detailsPath}>
        {e[item?.name]}
      </Link>
    );
  } else return e?.[item?.name];
}

export function filterPlaceHolder(prefix, suffix) {
  return prefix + suffix;
}

export const actionBodyTemplate = (rowData, items) => {
  const menuLeft = React.createRef();
  return (
    <React.Fragment>
      <div className="card flex justify-content-center table-ellipses-actions">
        <Menu
          itemProp={rowData}
          model={items}
          popup
          ref={menuLeft}
          id="popup_menu_left"
          popupAlignment="right"
        />
        <Button
          icon="pi pi-ellipsis-v"
          className="table-ellipses"
          onClick={(event) => menuLeft?.current?.toggle(event)}
          aria-controls="popup_menu_left"
          aria-haspopup
        />
      </div>
    </React.Fragment>
  );
};
export function columnContent(
  clas,
  column = [],
  detailUrlPrefix,
  concernId,
  columnClickable,
  urlPath,
  filterColumn = [],
  excColumns = [],
  sortable
) {
  let col;
  if (excColumns && !_.isEmpty(excColumns)) {
    col = column?.filter((item) => !excColumns?.includes(item.name));
  } else {
    col = column;
  }
  let header = col?.map((item, ind) => {
    const filterColumnCheck = () => {
      if (item?.filter) return item?.filter;
      else if (!_.isEmpty(filterColumn) && filterColumn?.includes(item.name))
        return true;
      else return false;
    };

    return (
      <Column
        key={ind}
        field={item?.name}
        body={(e) =>
          generateColumnContent(e, item, concernId, columnClickable, urlPath)
        }
        style={{ ...item?.style }}
        header={item?.label}
        filter={filterColumnCheck()}
        dataType={item?.dataType}
        className={item?.className ? item.className : className(clas, ind)}
        filterField={item?.filterField}
        filterElement={item?.filterElement}
        bodyClassName={item?.bodyClassName}
        filterPlaceholder={filterPlaceHolder("Search by ", item?.label)}
        sortable={sortable}
      />
    );
  });
  return header;
}
