import React, { useEffect } from "react";
import PrimarySideBar from "./sideBar/primarySideBar";
import "../assets/styles/inputComponent.scss";
import "../assets/styles/inputPrimaryComponent.scss";
import AddButtonSection from "./AddButtonSection";
import CalendarWIthHeadr from "./calendarWIthHeadr";

export default function DateSelection(props) {
  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    setVisible(props?.visible);
  }, [props?.visible]);

  return (
    <div>
      <PrimarySideBar
        className=""
        visible={visible}
        onHide={() => {
          setVisible(false);
          props?.onHide?.();
        }}
        header={<div className="sideBar-header">Schedule New Appointment</div>}
      >
        <div className="input-standerd-container h-inherit">
          
          <CalendarWIthHeadr header="New Appointment Day" {...props}/>
        </div>
        <div className="pt-5">
          <AddButtonSection buttons={props?.buttons} />
        </div>
      </PrimarySideBar>
    </div>
  );
}
