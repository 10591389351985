import React, { useState } from "react";
import Search from "../../components/Search";
import "../../assets/styles/Invoices.scss";
import UsersImg from "../../assets/images/user-bw.svg";
import User2Img from "../../assets/images/user-2-bw.svg";
import User3Img from "../../assets/images/user-3-bw.svg";
import User4Img from "../../assets/images/user-4-bw.svg";
import Payment from "../payment/payment";
import { formatNumber } from "../../utils/utils";
const Invoices = () => {
  const [invoiceDetails, setInvoiceDetails] = useState({});
  return (
    <div className="Invoices__main-container">
      {/* <div className="Invoices__search-container">
        <Search />
      </div> */}
      <div className="Invoices__card-container">
        <ul className="Invoices__card-holder">
          <li>
            <div className="card-info">
              <div className="card-left-sec">
                <p className="card-title">{invoiceDetails?.client || 0}</p>

                <p className="card-sub-info">Clients</p>
              </div>
              <div className="card-right-sec">
                <span className="card-icon bg-one">
                  <img src={UsersImg} alt="" />
                </span>
              </div>
            </div>
            <div className="card-line-divider"></div>
          </li>
          <li>
            <div className="card-info">
              <div className="card-left-sec">
                <p className="card-title">{invoiceDetails?.total || 0}</p>

                <p className="card-sub-info">Invoices</p>
              </div>
              <div className="card-right-sec">
                <span className="card-icon bg-two">
                  <img src={User2Img} alt="" />
                </span>
              </div>
            </div>
            <div className="card-line-divider"></div>
          </li>
          <li>
            <div className="card-info">
              <div className="card-left-sec">
                <p className="card-title">
                  {"₹" +
                    (invoiceDetails?.paid
                      ? formatNumber(invoiceDetails.paid)
                      : 0)}
                </p>

                <p className="card-sub-info">Paid</p>
              </div>
              <div className="card-right-sec">
                <span className="card-icon bg-three">
                  <img src={User3Img} alt="" />
                </span>
              </div>
            </div>
            <div className="card-line-divider"></div>
          </li>
          <li>
            <div className="card-info">
              <div className="card-left-sec">
                <p className="card-title">
                  {"₹" +
                    (invoiceDetails?.balance
                      ? formatNumber(invoiceDetails.balance)
                      : 0)}
                </p>

                <p className="card-sub-info">Unpaid</p>
              </div>
              <div className="card-right-sec">
                <span className="card-icon bg-four">
                  <img src={User4Img} alt="" />
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div>
        <Payment
          api="/api/getAllCollectablePayments"
          accordion
          isSearchEnabled
          searchField={[
            "collectable_payment_description_id",
            "patient_name",
            "total_collectable_amount",
            "total_paid_amount",
            "total_balance_amount",
            "patient_code",
          ]}
          requiredButton={["Edit", "Add Payment"]}
          patientId={""}
          noRequired={["payment_categories", "patient_id"]}
          setInvoiceDetails={setInvoiceDetails}
          headerAlias={{
            collectable_payment_description_id: "invoice",
            InvoiceDate: "Date",
            total_collectable_amount: "total_amount",
            total_paid_amount: "amount_paid",
            total_balance_amount: "balance",
            patient_code: "patient_id",
          }}
          rowExpansionTemplate={
            <div className="p-3 sub-data-grid-table">
              <Payment header={true} />
            </div>
          }
        />
      </div>
    </div>
  );
};
export default Invoices;
