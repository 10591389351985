import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/styles/toast.scss";

export default function ToastComponent({
  position = "top-right",
  autoClose = 3000,
  closeOnClick = true,
  draggable = true,
  pauseOnHover = true,
  theme = "light",
  newestOnTop = true,
  className = "custom-toast",
  ...props
}) {
  const [isToastActive, setIsToastActive] = useState(false);

  return (
    <>
      {isToastActive && <div className="blur-overlay"></div>}
      <ToastContainer
        position={position}
        autoClose={autoClose}
        closeOnClick={closeOnClick}
        draggable={draggable}
        pauseOnHover={pauseOnHover}
        theme={theme}
        newestOnTop={newestOnTop}
        onOpen={() => setIsToastActive(true)}  
        onClose={() => setIsToastActive(false)} 
        className={className}
        {...props}
      />
    </>
  );
}

export function showToast(type, message) {
  return toast(<span className="public_sans_400 text-15px text-secondaryTextColor">{message}</span>, {
    type: type,
  });
}