const Payment = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 3.75H5.25C4.42157 3.75 3.75 4.42157 3.75 5.25V14.25C3.75 15.0784 4.42157 15.75 5.25 15.75H12.75C13.5784 15.75 14.25 15.0784 14.25 14.25V5.25C14.25 4.42157 13.5784 3.75 12.75 3.75H11.25"
      stroke="#2F2B3D"
      stroke-opacity="0.9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="6.75"
      y="2.25"
      width="4.5"
      height="3"
      rx="1.5"
      stroke="#2F2B3D"
      stroke-opacity="0.9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5 8.25H8.625C8.00368 8.25 7.5 8.75368 7.5 9.375C7.5 9.99632 8.00368 10.5 8.625 10.5H9.375C9.99632 10.5 10.5 11.0037 10.5 11.625C10.5 12.2463 9.99632 12.75 9.375 12.75H7.5"
      stroke="#2F2B3D"
      stroke-opacity="0.9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.75 12.75C9.75 12.3358 9.41421 12 9 12C8.58579 12 8.25 12.3358 8.25 12.75H9.75ZM8.25 13.5C8.25 13.9142 8.58579 14.25 9 14.25C9.41421 14.25 9.75 13.9142 9.75 13.5H8.25ZM9.75 7.5C9.75 7.08579 9.41421 6.75 9 6.75C8.58579 6.75 8.25 7.08579 8.25 7.5H9.75ZM8.25 8.25C8.25 8.66421 8.58579 9 9 9C9.41421 9 9.75 8.66421 9.75 8.25H8.25ZM8.25 12.75V13.5H9.75V12.75H8.25ZM8.25 7.5V8.25H9.75V7.5H8.25Z"
      fill="#2F2B3D"
      fill-opacity="0.9"
    />
  </svg>
);

export default Payment;
