import React from "react";
import "../assets/styles/secoundaryReportCard.scss";

export default function SecoundaryReportCard({ reportList }) {
  return (
    <div className="Secoundary__report-card">
      <ul className="Secoundary__card-holder">
        {reportList?.map?.((item, index) => (
          <li>
            <div className="card-info">
              <div className="card-left-sec">
                <p className="card-title">₹ {item?.count}</p>
                <p className="card-sub-info">{item?.title}</p>
              </div>
              <div className="card-right-sec">
                <span className="card-icon bg-one">
                  <img src={item?.icon} alt="img" />
                </span>
              </div>
            </div>
            {!(index == reportList?.length - 1) && (
              <div className="card-line-divider"></div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
