import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import "../assets/styles/verticalBarChart.scss";

export default function VerticalBarChart({
  className = "standart-verticalchart-mc",
  graphData,
}) {
  const generateHslColors = (numColors) =>
    Array.from(
      { length: numColors },
      (_, i) => `hsl(${(i * 360) / numColors}, 70%, 50%)`
    );

  const colors = generateHslColors;

  const [state, setState] = React.useState({
    options: {
      chart: {
        type: "bar",
        offsetX: -20,
      },
      plotOptions: {
        bar: {
          barHeight: 12,
          distributed: true,
          horizontal: true,
          borderRadius: 6,
          borderRadiusApplication: "end",
        },
      },
      colors: colors(5),
      xaxis: {
        categories: [],
        position: "top",
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      legend: {
        offsetX: -80,
        show: true,
        width: 250,
        position: "right",
        labels: {
          colors: "#808390",
        },
        markers: {
          shape: "circle",
        },
        itemMargin: {
          vertical: 10,
        },
      },
      grid: {
        show: false,
      },
    },
    series: [
      {
        data: [120, 138, 47, 110, 58, 30],
      },
    ],
  });

  useEffect(() => {
    if (graphData) {
      setState((prevState) => ({
        ...prevState,
        series: graphData.series,
        options: {
          ...prevState.options,
          colors: colors(graphData.categories.length || 0),
          xaxis: {
            ...prevState.options.xaxis,
            categories: graphData.categories,
          },
          legend: {
            ...prevState.options.legend,
            formatter: function (seriesName, opts) {
              const percentages = graphData.percentage || [];
              return `<div class="legend-container"><span class="legend-label">${seriesName}</span><span class="legend-percentage">${
                percentages[opts.seriesIndex] || 0
              }%</span></div>`;
            },
          },
        },
      }));
    }
  }, [graphData]);

  return (
    <div className={className}>
      <Chart
        options={state.options}
        series={state.series}
        type="bar"
        // height={400}
        height={graphData?.categories?.length? (graphData.categories.length * 30.3) + 65 : 250}
        width={700}
      />
    </div>
  );
}

