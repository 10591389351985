import React, { useEffect, useState } from "react";
import { commonApi } from "../../utils/apiService";
import {
  convetToSearchField,
  dataToNameAndLabel,
  orderObj,
} from "../../utils/utils";
import DataGrid from "../../commonComponents/dataGrid/dataGrid";
import DataGridHeader from "../../commonComponents/dataGrid/dataGridAddPatientHeader";
import moment from "moment";

export default function AllLogistic({
  api,
  dataKey,
  refresh,
  checkBox,
  handleClick,
  setCheckBox,
  optionsView,
  searchOption,
  secoundaryButton,
  isButtonEnabledLeft,
  selectable = false,
  currentPageHeader,
  searchField = ["patient_name", "phone_no", "patient_code"],
  columnArray = [],
  ...props
}) {
  const [values, setValues] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [navigator, setNavigator] = useState({
    page: 1,
    first: 0,
    rows: 10,
    totalRecords: 1,
    sortBy: "patient_name",
    sortDir: "asc",
    searchCriteria: { status: searchOption },
    searchValue: "",
  });
  const init = async (navigator) => {
    let currentObj = {
      query: {
        page: navigator?.page,
        limit: navigator?.rows,
        searchCriteria: {
          status: navigator?.searchCriteria?.status,
          searchvalue: navigator?.searchCriteria?.searchvalue || "",
          transportation_date:
            navigator?.searchCriteria?.transportation_date || "",
        },
        sortFields: navigator?.sortFields,
      },
    };
    let headers = {
      "Content-Type": "application/json",
    };
    let orderArray = [
      "patient_name",
      "patient_code",
      "phone_no",
      "status",
      "transportation_date",
    ];

    if (columnArray && columnArray?.length > 0) {
      orderArray = [...orderArray, ...columnArray];
    }
    await commonApi(api, currentObj, {
      headers,
    })
      .then((data = []) => {
        if (data?.data?.data) {
          const finalData = data?.data?.data;
          setNavigator((prev) => ({
            ...prev,
            totalRecords: data?.data?.total,
            page: data?.data?.currentPage,
          }));
          setProducts(
            orderObj(
              orderArray,
              dataToNameAndLabel(finalData, null, null, null, null, {
                patient_code: "patient_id",
                transportation_date: "trans_date",
                no_of_followup_calls: "follow_up_calls",
                phone_no: "phone number",
                status: "surgery type",
                ...props?.alias,
              })?.filter((item) => orderArray?.includes(item?.name))
            )
          );
          setValues(finalData);
          setLoading(false);
        } else {
          setNavigator((prev) => ({
            ...prev,
            totalRecords: 1,
            page: 1,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function onPageChange(event) {
    setLoading(true);
    setNavigator((prev) => ({
      ...prev,
      first: event?.first,
      page: event.page + 1,
    }));
    init({
      ...navigator,
      first: event?.first,
      page: event.page + 1,
    });
  }

  const onRowCountChange = (e) => {
    setLoading(true);
    setNavigator((prev) => ({
      ...prev,
      rows: e?.target?.value,
      page: 1,
      first: 0,
    }));
    init({
      ...navigator,
      page: 1,
      first: 0,
      rows: e?.target?.value,
    });
  };
  useEffect(() => {
    setNavigator((prev) => ({
      ...prev,
      searchCriteria: {
        status: searchOption,
        searchvalue: "",
      },
    }));
    init({
      ...navigator,
      searchCriteria: {
        status: searchOption,
        searchvalue: "",
      },
    });
  }, [refresh]);

  const onSortClick = (event) => {
    setLoading(true);
    setNavigator((prev) => ({
      ...prev,
      sortBy: event?.sortField,
      sortDir: event?.sortOrder == 1 ? "asc" : "desc",
      sortFields: [[event?.sortField, event?.sortOrder == 1 ? "ASC" : "DESC"]],
    }));
    init({
      ...navigator,
      sortBy: event?.sortField,
      sortDir: event?.sortOrder == 1 ? "asc" : "desc",
      sortFields: [[event?.sortField, event?.sortOrder == 1 ? "ASC" : "DESC"]],
    });
  };

  const onRowClick = (event) => {
    // navigate(`/visits/${event?.data?.patient_id}`);
  };

  function handleDateChange(date) {
    setNavigator((prev) => ({
      ...prev,
      searchCriteria: {
        transportation_date: date? moment(date).format("YYYY-MM-DD") : "",
        ...prev.searchCriteria,
      },
    }));
    const { transportation_date, ...rest } = navigator?.searchCriteria
    init({
      ...navigator,
      searchCriteria: {
        transportation_date: date? moment(date).format("YYYY-MM-DD") : "",
        ...rest,
      },
    });
  }
  function handleSearchChange(value) {
    setNavigator((prev) => ({
      ...prev,
      searchCriteria: {
        status: searchOption,
        searchvalue: value,
      },
    }));
    init({
      ...navigator,
      searchCriteria: {
        status: searchOption,
        searchvalue: value,
      },
    });
  }
  return (
    <div>
      <DataGrid
        header={
          <DataGridHeader
            secoundaryButton={secoundaryButton}
            handleClick={handleClick}
            checkBox={checkBox}
            isSearchEnabled={true}
            isCalenderEnabled={true}
            isButtonEnabledLeft={isButtonEnabledLeft}
            isButtonEnabledRight={false}
            currentPageHeader={currentPageHeader}
            onChange={handleSearchChange}
            handleDateChange={handleDateChange}
            searchPlaceHolder={"Search Patients"}
          />
        }
        selectable={selectable}
        onRowCountChange={onRowCountChange}
        onPageChange={onPageChange}
        onSortClick={onSortClick}
        navigator={navigator}
        column={products}
        value={values}
        loading={loading}
        isAccordionEnabled={false}
        isPaginatorEnabled={true}
        selection={checkBox}
        dataKey={dataKey}
        sortable
        optionsView={optionsView}
        onSelectionChange={(e) => setCheckBox(e.value)}
      />
    </div>
  );
}
