import React, { useRef } from "react";
import { Button } from "primereact/button";
import "../assets/styles/buttonPrimaryComponents.scss";

export default function ButtonComponent({ onClick, spType, ...props }) {
  const fileRef = useRef(null);

  const handleImageUpload = (event) => {
    event.preventDefault();
    fileRef.current.click();
  };

  if (spType == "file") {
    return (
      <div>
        <input
          type={"file"}
          id={"file"}
          ref={fileRef}
          placeholder={"placeholder"}
          className="inter-500 text-sm input-text p-3 outline-green pt-13p pb-14p outline-green:hover"
          onChange={onClick}
          hidden
        />
        <Button
          icon={props.icon}
          disabled={props.disabled}
          label={props.label}
          onClick={handleImageUpload}
          onChange={props.onChange}
          className={props.className}
          {...props}
        />
        {(props?.info || props?.watch?.(props?.watchState)) && (
          <div className="msgText font-normal text-sm p-2 pl-0">
            {props?.watch ? props?.watch?.(props?.watchState) : props?.info}
          </div>
        )}
      </div>
    );
  }
  return (
    <div>
      <Button
        icon={props.icon}
        disabled={props.disabled}
        label={props.label}
        onClick={onClick}
        onChange={props.onChange}
        className={props.className}
        {...props}
      />
    </div>
  );
}
