import React from "react";
import { Steps } from "rsuite";
import "../assets/styles/steps.scss";

export default function StepsComponent({
  current = 1,
  stepList,
  vertical,
  children,
}) {
  return (
    <React.Fragment>
      <Steps current={1}>
        {stepList?.map?.((item) => (
          <Steps.Item
            title={item.title}
            key={item.title}
            description={item.description}
          />
        ))}
      </Steps>
    </React.Fragment>
  );
}
