import { Button } from "primereact/button";
import "../assets/styles/profileView.scss";
import React from "react";
import ButtonPrimaryComponent from "./buttonPrimaryComponents";
import LoaderProgressBar from "../commonComponents/loaderProgressBar";
import Loader from "../commonComponents/loader";

export default function ProfileView({
  profile,
  button,
  loading,
  parentClass,
  ...props
}) {
  return (
    <div className="ProfileView__card-container">
      <ul className="ProfileView__card-holder">
        <li>
          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className="card-details">
                <div className="card-left-sec">
                  <img
                    className="img-profile-view"
                    src={
                      profile?.imgUrl
                        ? profile?.imgUrl
                        : profile?.gender?.value == "female"
                        ? require("../assets/images/no-img-female-user.jpg")
                        : require("../assets/images/no-picture.jpg")
                    }
                    alt=""
                  />
                </div>
                <div className="card-right-sec">
                  <div className="card-patient-user-actions">
                    <span className="card-patient-user-name">
                      {profile?.name}
                    </span>
                    <div className={`card-patient-actions ${parentClass}`}>
                      {button?.map((item) => (
                        <ButtonPrimaryComponent
                          className={item?.class}
                          icon={item?.icon}
                          type={item?.type}
                          label={item?.label}
                          disabled={item?.disabled || loading}
                          onClick={item?.onClick}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="card-line-divider"></div>
                  <div className="card-profile-user-patients">
                    {profile &&
                      Object?.keys(profile)?.map((item, ind) => {
                        if (
                          item === "name" || item === "imgUrl"
                        )
                          return <></>;
                        return (
                          <>
                            {ind !== 0 && (
                              <div className="card-patient-line-divider-y" />
                            )}
                            <div className="card-patient-info">
                              <span className="card-patient-heading">
                                {profile[item]?.name === "dob" ? "DOB" : profile[item]?.name}
                              </span>
                            {profile[item]?.value ?
                              <span className="card-patient-title">
                                {profile[item]?.value}
                              </span>
                            :  
                              <span className="card-patient-title text-center">
                                -
                              </span>
                            }
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </li>
      </ul>
    </div>
  );
}
