import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import React, { useEffect } from "react";

export default function PrimarySearch({
  searchPlaceHolder,
  children,
  placeholder,
  onChange = () => {},
  directChange = false,
  ...props
}) {
  const [value, setValue] = React.useState("");

  useEffect(() => {
    let handler;
    handler = setTimeout(() => {
      onChange(value);
    }, 500);
    return () => clearTimeout(handler);
  }, [value]);

  const handleChange = (e) => {
    if (directChange) {
      onChange(e.target.value);
    } else {
      setValue(e.target.value);
    }
  };

  return (
    <div className={props.headerClass}>
      <IconField iconPosition="left">
        <InputText
          placeholder={searchPlaceHolder ? searchPlaceHolder : "Search"}
          className="w-full"
          onChange={handleChange}
        />
      </IconField>
    </div>
  );
}
