import React, { useState } from "react";
import { IconField } from "primereact/iconfield";
import "./Search.scss";
import AvatarIcon from "../assets/images/avatar.svg";
import { Button } from 'primereact/button';
import { Menu } from "primereact/menu";
import { useNavigate } from "react-router-dom";
import { commonGetApi } from "../utils/apiService";
const Search = ({ onAvatarClick }) => {
  const overlayRef = React.useRef(null);
  const navigate = useNavigate();

  const toggleOverlay = (e) => {
    if (overlayRef.current) {
      overlayRef.current.toggle(e); // Pass the event to toggle the overlay
    } else {
      console.error("OverlayRef is null");
    }
  };
  return (
    <div className="Search__container">
      <div className="Search__left-sec">
        <IconField iconPosition="left">
          {/* <InputIcon className="pi pi-search"> </InputIcon> */}
          {/* <InputText v-model="value1" placeholder="Search" /> */}
        </IconField>
      </div>
      <div className="Search__right-sec">
        <ul className="Search__action-items">
          <li>
            <img
              className="border-circle"
              src={require("../assets/images/no-picture.jpg")}
              alt=""
              onClick={toggleOverlay}
            />
          </li>
        </ul>
      </div>
      <Menu
        model={[
          {
            template: (
              <>
              <div className="Search__profile-container pr-4 pl-4 pb-3">
                <div className="Search__profile-card">
                  <img style={{height:"50px"}} className="border-circle" src={require("../assets/images/no-picture.jpg")}/>
                  <div className="profile-info">
                    <p className="profile-name mt-0 mb-2">John Doe</p>
                    <p className="profile-role m-0">Role</p>
                  </div>
                </div>
              </div>
              <div className="pr-4 pl-4 pt-3">
               <Button className="w-full font-medium text-base Search__bg-color" label="Logout" icon="pi pi-sign-out" iconPos="right"  onClick={() => {
                   commonGetApi("/logout").then((data) => {
                     navigate("/");
                   });
                 }}/>
              </div>
             </>
            ),
          },
        ]}
        popup
        ref={overlayRef}
        id="popup_menu_right"
        popupAlignment="right"
      />
    </div>
  );
};
export default Search;
