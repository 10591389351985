const Prescription = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 14.25V2.25H7.875C9.73896 2.25 11.25 3.76104 11.25 5.625C11.25 7.48896 9.73896 9 7.875 9H4.5"
      stroke="#2F2B3D"
      stroke-opacity="0.9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.25 15.75L7.5 9"
      stroke="#2F2B3D"
      stroke-opacity="0.9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.75 15.75L14.25 11.25"
      stroke="#2F2B3D"
      stroke-opacity="0.9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Prescription;
