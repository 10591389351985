import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../assets/styles/menu.scss";

const SidebarMenu = ({ menuItems }) => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(null);
  const [expandedItems, setExpandedItems] = useState({});
  const [activeParentItems, setActiveParentItems] = useState({});

  useEffect(() => {
    const findActiveItem = (items, parentKey = "") => {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const uniqueKey = parentKey ? `${parentKey}-${i}` : `${i}`;

        if (
          item.command &&
          item.command.toString().includes(location.pathname)
        ) {
          setActiveItem(uniqueKey);
          if (parentKey) {
            setActiveParentItems((prev) => ({
              ...prev,
              [parentKey]: true,
            }));
          }
          return uniqueKey;
        }

        if (item.items) {
          const nestedActiveKey = findActiveItem(item.items, uniqueKey);
          if (nestedActiveKey) {
            setExpandedItems((prevExpandedItems) => ({
              ...prevExpandedItems,
              [uniqueKey]: true,
            }));
            setActiveParentItems((prev) => ({
              ...prev,
              [uniqueKey]: true,
            }));
            return nestedActiveKey;
          }
        }
      }
      return null;
    };

    findActiveItem(menuItems);
  }, [location.pathname, menuItems]);

  const handleItemClick = (uniqueKey, command) => {
    setActiveItem(uniqueKey);
    setActiveParentItems({});
    if (command) command();
  };

  const toggleExpand = (uniqueKey) => {
    setExpandedItems((prevExpandedItems) => ({
      ...prevExpandedItems,
      [uniqueKey]: !prevExpandedItems[uniqueKey],
    }));
  };

  const renderMenuItems = (items, parentKey = "") => {
    return items.map((item, index) => {
      const uniqueKey = parentKey ? `${parentKey}-${index}` : `${index}`;
      const isExpanded = expandedItems[uniqueKey];
      const hasChildren = item.items && item.items.length > 0;
      const isActive = activeItem === uniqueKey || activeParentItems[uniqueKey];

      return (
        <div key={uniqueKey}>
          <div
            className={`menu-item ${isActive ? "active" : ""} ${
              uniqueKey.length > 1 ? "child" : ""
            }`}
            onClick={() => {
              hasChildren
                ? toggleExpand(uniqueKey)
                : handleItemClick(uniqueKey, item.command);
            }}
          >
            <div className="icon w-20px text-center">
              {typeof item.icon === "string" ? (
                <i className={item.icon}></i>
              ) : (
                item.icon
              )}
            </div>
            <span>{item.label}</span>

            {/* {hasChildren && (
              <button onClick={() => toggleExpand(uniqueKey)}>
                {isExpanded ? "-" : "+"}
              </button>
            )} */}
          </div>

          {hasChildren && isExpanded && (
            <div className="submenu">
              {renderMenuItems(item.items, uniqueKey)}
            </div>
          )}
        </div>
      );
    });
  };

  return <div className="sidebar">{renderMenuItems(menuItems)}</div>;
};

export default SidebarMenu;
