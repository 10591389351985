import React from "react";
import CustomCalendar from "./customCalendar";
import moment from "moment";
import "../assets/styles/calendarWithHeader.scss";

export default function CalendarWIthHeadr({
  header = "",
  date = new Date(),
  setDate,
}) {
  return (
    <div className="calendar-custom-header">
      <div className="calendar-header">
        <div className="flex flex-column gap-2">
          <div className="heading">{header}</div>
          <div className="date">{moment(date).format("ddd,   MMM MM")}</div>
        </div>
      </div>
      <CustomCalendar selectedDate={date} setSelectedDate={setDate} />
    </div>
  );
}
