import React from "react";
import SideBar from "../../commonComponents/sideBar";

export default function PrimarySideBar({ visible, children, header, className, ...props }) {
  return (
    <div className="primary-sidebar">
      <SideBar
        visible={visible}
        customProp={{
          header: header,
        }}
        className={`custom-primary-sidebar ${className}`}
        {...props}
      >
        <div>{children}</div>
      </SideBar>
    </div>
  );
}
