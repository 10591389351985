import React from "react";
import InputComponent from "../commonComponents/inputComponent";
import { ErrorMessage } from "@hookform/error-message";
import "../assets/styles/inputPrimaryComponent.scss";
import Cancel from "../assets/images/cancel.svg";

export default function InputPrimaryComponents({ inputParentClass,paddingStyle, ...props }) {
  return (
    <div className={`${inputParentClass} ${paddingStyle ? 'pb-2' : 'pb-4'}`}>
      <div className="InputPrimaryComponentsContainer font-normal">
        <label
          for={props.label}
          className="inputContentLabel text-sm text-primaryColor"
        >
          {props.label}
          {props.required && <span className="star-icon-color">*</span>}
        </label>
        <div className={`InputPrimaryComponents custom-input-component ${props?.searchIcon && "flex"}`}>
          {props?.searchIcon && <i className="pi pi-search flex align-items-center" />}
          <InputComponent {...props} />
        </div>
        {props?.infoText && (
          <div className="text-secondaryTextColor text-sm pl-3">
            {props?.infoText}
          </div>
        )}

        {props?.value && !props?.inter && (
          <div className="flex pt-3 inputValueController">
            {props?.value?.map((item, index) => (
              <div className="inputValueContainer">
                {item.label}
                <img className="cursor-pointer" src={Cancel} alt="" onClick={() => props?.onClick?.(props?.name, item.value)}/>
              </div>
            ))}
          </div>
        )}
        {props?.errors && (
          <small className="text-red-600 inter-400">
            <ErrorMessage errors={props?.errors} name={props?.name} />
          </small>
        )}
        {props?.error && !props?.name && (
          <small className="text-red-600 inter-400">{props?.error}</small>
        )}
      </div>
    </div>
  );
}
