import React from "react";
import InputIterator from "./inputIterator";

export default function InputHeaderWithWrap({
  type,
  header,
  control,
  errors,
  onClick = () => {},
  formRender,
  customClass,
}) {
  const renderInput = (type) => {
    switch (type) {
      case "input-group":
        return (
          <div className={`flex w-full placeHolder-primaryBlack`}>
            <InputIterator
              control={control}
              errors={errors}
              formRender={formRender}
              onClick={onClick}
            />
          </div>
        );

      case "input-with-value":
        return (
          <div className="flex w-full placeHolder-primaryBlack">
            <InputIterator
              control={control}
              errors={errors}
              formRender={formRender}
              onClick={onClick}
            />
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <div className={`card flex justify-content-center ${customClass}`}>
      <div className="w-full">
        {header && (
          <div className="public_sans_500 text-secondaryTextColor text-lg pb-4">
            {header}
          </div>
        )}
        {renderInput(type)}
      </div>
    </div>
  );
}
