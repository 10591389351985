import React from "react";
import InputPrimaryComponents from "./inputPrimaryComponents";

export default function InputIterator({
  control,
  errors,
  onClick,
  formRender,
}) {
  return (
    <React.Fragment>
      {formRender?.map((item, index) => {
        if (item?.item) {
          return (
            <div className="grid" key={index}>
              {item?.item?.map((item, index) => {
                return (
                  <div className="col-6">
                    <InputPrimaryComponents
                      key={index}
                      type={item.type}
                      control={control}
                      errors={errors}
                      name={item.name}
                      label={item.label}
                      rules={item.rules}
                      value={item?.value}
                      options={item.options}
                      maxDate={item?.maxDate}
                      minDate={item?.minDate}
                      infoText={item.infoText}
                      required={item.required}
                      disabled={item?.disabled}
                      className={item?.className}
                      autoFocus={item?.autoFocus}
                      placeholder={item?.placeholder}
                      maxLength={item?.maxLength}
                      inputParentClass={item?.inputParentClass}
                      onClick={onClick}
                    />
                  </div>
                );
              })}
            </div>
          );
        }
        return (
          <InputPrimaryComponents
            key={index}
            type={item.type}
            control={control}
            errors={errors}
            name={item.name}
            label={item.label}
            rules={item.rules}
            value={item?.value}
            options={item.options}
            minDate={item?.minDate}
            maxDate={item?.maxDate}
            infoText={item.infoText}
            required={item.required}
            disabled={item?.disabled}
            autoFocus={item?.autoFocus}
            className={item?.className}
            nestedInput={item?.nestedInput}
            placeholder={item?.placeholder}
            maxLength={item?.maxLength}
            nestedOnClick={item?.nestedOnClick}
            inputParentClass={item?.inputParentClass}
            onClick={onClick}
          />
        );
      })}
    </React.Fragment>
  );
}
