import React, { useEffect } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import "../assets/styles/tabView.scss";
import { listTabView } from "../utils/utils";

export default function ListTabView({
  type,
  listComponent,
  defaultIndex,
  index,
  tabClass,
}) {
  const [activeIndex, setActiveIndex] = React.useState(defaultIndex);

  useEffect(() => {
    setActiveIndex(defaultIndex);
  }, [defaultIndex]);

  return (
    <div className={listTabView(type)}>
      <TabView
        activeIndex={activeIndex}
        className={`p-tabview-custom ${activeIndex == index && tabClass}`}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        {listComponent?.map((item, index) => (
          <TabPanel
            header={item?.header}
            key={index}
            className={item?.className}
          >
            {item?.content}
          </TabPanel>
        ))}
      </TabView>
    </div>
  );
}
