import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

export default function LineChart({ graphData }) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const data = {
      labels: graphData?.labels || [],
      datasets: [
        {
          data: graphData?.data || [],
          borderColor: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            if (!chartArea) {
              return;
            }
            const gradient = ctx.createLinearGradient(
              chartArea.left,
              0,
              chartArea.right,
              0
            );
            gradient.addColorStop(0, "#4D44B5");
            gradient.addColorStop(1, "#FF5BEF");
            return gradient;
          },
          borderWidth: 3,
          pointBackgroundColor: "#fff",
          pointRadius: 3,
          pointHoverRadius: 5,
          tension: 0.4,
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            color: "#646464",
          },
          border: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
          ticks: {
            color: "#646464",
          },
          border: {
            display: false,
          },
        },
      },
    };
    setChartData(data);
    setChartOptions(options);
  }, [graphData]);

  return (
    <div className="card">
      <Chart type="line" data={chartData} options={chartOptions} />
    </div>
  );
}
