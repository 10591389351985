import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import DataGrid from "../../commonComponents/dataGrid/dataGrid";
import DataGridHeader from "../../commonComponents/dataGrid/dataGridAddPatientHeader";
import { convetToSearchField, dataToNameAndLabel } from "../../utils/utils";
import { commonApi } from "../../utils/apiService";
import AddPayments from "./addPayment";
import AddInvoice from "./addInvoice";
import { Button } from "primereact/button";
import _ from "lodash";

const Payment = forwardRef(
  (
    {
      header,
      rowExpansionTemplate,
      accordion,
      api,
      data,
      noRequired = ["payment_categories"],
      requiredButton,
      setInvoiceDetails,
      ...props
    },
    ref
  ) => {
    const [products, setProducts] = useState([]);
    const [values, setValues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState(null);
    const [addInvoiceVisisble, setAddInvoiceVisisble] = useState(false);
    const [addPaymentVisisble, setAddPaymentVisisble] = useState(false);
    const [childData, setChildData] = useState(null);
    const [metaData, setMetaData] = useState({});
    const [navigator, setNavigator] = useState({
      page: 1,
      first: 0,
      rows: 10,
      totalRecords: 1,
    });
    useImperativeHandle(ref, () => ({
      callChild() {
        console.log("callChild");
      },
    }));

    const init = async (navigator) => {
      let currentObj = {
        query: {
          page: navigator?.page,
          limit: navigator?.rows,
          sortFields: navigator?.sortFields,
          searchCriteria: 
            props?.patientId? props?.patientId :
            navigator?.searchCriteria,
          
        },
      };

      if (!api) return setLoading(false);
      await commonApi(api, currentObj) //props?.api,
        .then((data = []) => {
          if (data?.data?.data) {
            let finalData = data?.data?.data;
            const updatedPatient = finalData;
            setInvoiceDetails({
              client: data?.data?.clients,
              balance: data?.data?.total_balance_amount,
              paid: data?.data?.total_paid_amount,
              totalAmount: data?.data?.total_collectable_amount,
              total: data?.data?.total,
            });
            setNavigator((prev) => ({
              ...prev,
              totalRecords: data?.data?.total,
              page: data?.data?.currentPage,
            }));
            setProducts(
              dataToNameAndLabel(
                finalData,
                null,
                null,
                noRequired,
                null,
                props?.headerAlias
              )
            );
            setValues(updatedPatient);
            setLoading(false);
          } else {
            setNavigator((prev) => ({
              ...prev,
              totalRecords: 1,
              page: 1,
            }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    function handleClick(event) {
      setAddInvoiceVisisble(true);
    }

    function handleSeachChange(value) {
      props?.searchField &&
        setNavigator((prev) => ({
          ...prev,
          // searchCriteria: convetToSearchField(props?.searchField, value),
          searchCriteria: value,
        }));
      props?.searchField &&
        init({
          ...navigator,
          searchCriteria: value,
          // searchCriteria: convetToSearchField(props?.searchField, value),
        });
    }

    const onRowCountChange = (e) => {
      setLoading(true);
      setNavigator((prev) => ({
        ...prev,
        rows: e?.target?.value,
        page: 1,
        first: 0,
      }));
      init({
        ...navigator,
        page: 1,
        first: 0,
        rows: e?.target?.value,
      });
    };

    function onPageChange(event) {
      setLoading(true);
      setNavigator((prev) => ({
        ...prev,
        first: event?.first,
        page: event.page + 1,
      }));
      init({
        ...navigator,
        first: event?.first,
        page: event.page + 1,
      });
    }

    const onSortClick = (event) => {
      setLoading(true);
      setNavigator((prev) => ({
        ...prev,
        sortBy: event?.sortField,
        sortDir: event?.sortOrder == 1 ? "asc" : "desc",
        sortFields: [
          [event?.sortField, event?.sortOrder == 1 ? "ASC" : "DESC"],
        ],
      }));
      init({
        ...navigator,
        sortBy: event?.sortField,
        sortDir: event?.sortOrder == 1 ? "asc" : "desc",
        sortFields: [
          [event?.sortField, event?.sortOrder == 1 ? "ASC" : "DESC"],
        ],
      });
    };

    const subTableInit = (data) => {
      data?.[0]?.payment_categories &&
        setChildData(data?.[0]?.payment_categories);
    };

    const onRowToggle = (event) => {
      event?.data && !_.isEmpty(event?.data) && subTableInit(event?.data);
      setExpandedRows(event.data);
    };

    useEffect(() => {
      if (props?.paymentData) {
        setProducts(
          dataToNameAndLabel(
            props?.paymentData,
            null,
            null,
            null,
            null,
            props?.headerAlias
          )
        );
        setValues(props?.paymentData);
      }
    }, [props?.paymentData]);

    const itemRenderer = (item, props) => {
      return (
        <div className="p-menuitem-content">
          {requiredButton?.includes("Add Payment") &&
            item.label === "Add Payment" && (
              <Button
                className="card-button-add-button"
                icon="pi pi-plus"
                label="Add Payment"
                onClick={() => {
                  setMetaData({
                    invoice: props?.props?.itemProp,
                  });
                  setAddPaymentVisisble(true);
                }}
              />
            )}
          {requiredButton?.includes("Edit") && item.label === "Edit" && (
            <Button
              className="card-button-edit"
              label="Edit"
              onClick={() => {
                setMetaData({
                  invoice_edit: true,
                  invoice_id:
                    props?.props?.itemProp?.collectable_payment_description_id,
                });
                setAddInvoiceVisisble(true);
                // setOptionalVisible((prev) => ({
                //   ...prev,
                //   editPatient: true,
                //   selectedPatient: props?.props?.itemProp?.patient_id,
                // }));
              }}
            />
          )}
          {requiredButton?.includes("delete") && item.label === "Remove" && (
            <Button
              className="card-button-delete"
              label="Delete"
              onClick={() => {
                // setOptionalVisible((prev) => ({
                //   ...prev,
                //   deletePatient: true,
                //   selectedPatient: props?.props?.itemProp,
                // }));
              }}
            />
          )}
        </div>
      );
    };

    const allowExpansion = (rowData) => {
      return Object.keys(rowData).length > 0;
    };

    useEffect(() => {
      init(navigator);
    }, []);

    return (
      <div>
        <DataGrid
          header={
            header ? (
              header
            ) : (
              <DataGridHeader
                handleClick={handleClick}
                isSearchEnabled={props?.isSearchEnabled}
                isCalenderEnabled={false}
                isButtonEnabledLeft={false}
                isButtonEnabledRight={true}
                searchPlaceHolder={"Search Invoices"}
                currentPageHeader={"Invoice List"}
                onChange={handleSeachChange}
              />
            )
          }
          isPaginatorEnabled={props?.paymentData ? false : true}
          onSortClick={onSortClick}
          onRowCountChange={onRowCountChange}
          onPageChange={onPageChange}
          expandedRows={expandedRows}
          onRowToggle={onRowToggle}
          // onRowExpand={onRowExpand}
          // onRowCollapse={onRowCollapse}
          rowExpansionTemplate={
            <div className="p-3 sub-data-grid-table">
              <Payment
                header={true}
                paymentData={childData}
                headerAlias={{ name: "category", collectable_amount: "amount" }}
              />
            </div>
          }
          allowExpansion={allowExpansion}
          isAccordionEnabled={accordion}
          sortable={true}
          navigator={navigator}
          column={products}
          value={values}
          loading={loading}
          optionsView={
            props?.paymentData
              ? ""
              : [
                  {
                    items: [
                      {
                        label: "Add Payment",
                        icon: "pi pi-pencil",
                        template: itemRenderer,
                      },
                      {
                        label: "Edit",
                        icon: "pi pi-upload",
                        template: itemRenderer,
                      },
                      {
                        label: "Remove",
                        icon: "pi pi-upload",
                        template: itemRenderer,
                      },
                    ],
                  },
                ]
          }
        />
        {addInvoiceVisisble && (
          <AddInvoice
            edit={metaData?.invoice_edit}
            invoice_id={metaData?.invoice_id}
            visible={addInvoiceVisisble}
            patientId={props?.patientId}
            setVisible={(value) => {
              setMetaData({});
              setAddInvoiceVisisble(value);
            }}
            init={() => init(navigator)}
          />
        )}
        {addPaymentVisisble && (
          <AddPayments
            invoice={metaData?.invoice}
            visible={addPaymentVisisble}
            setVisible={(value) => {
              setMetaData({});
              setAddPaymentVisisble(value);
            }}
            init={() => init(navigator)}
          />
        )}
      </div>
    );
  }
);

export default Payment;
