import axios from "axios";

// Create an Axios instance with custom configuration
export const axiosInstance = axios.create({
    baseURL: process.env?.REACT_APP_CURRENT_URL || 'https://web-mcms-dev-backend-01.azurewebsites.net/', // Base URL for all requests made using this instance
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.response.use(function (res) {
    if (res.status == 200 && res.config.url == "/login" && window.top.location.pathname == "/") {
        window.top.location.href = "/dashboard"
    }
    return res;
}, function (err) {
    if (err?.response?.status === 401 || err?.response?.status === 403 && window.top.location.pathname != "/") {
        window.top.location.href = "/"
    } else {
        return err
    }
})

axiosInstance.interceptors.request.use(req => {
    return req
})