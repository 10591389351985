import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { Dialog } from "primereact/dialog";
import "./capturePhoto.scss";
import AddButtonSection from "../AddButtonSection";

const CapturePhoto = ({
  imgWidth,
  imgHeight,
  webcamWidth,
  webcamHeight,
  onImageCapture,
}) => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [visible, setVisible] = useState(false);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  const videoConstraints = {
    width: webcamWidth ? webcamWidth : 410,
    height: webcamHeight ? webcamHeight : 320,
    facingMode: "user",
  };

  const buttons = [
    {
      type: "primarySuccessButton",
      label: "Capture Photo",
      class: "col-3",
      onClick: capture,
    },
    {
      type: "secondaryButton",
      label: "Refresh Cature",
      class: "col-3",
      onClick: () => setImgSrc(null),
    },
  ];

  return (
    <>
      <Dialog
        header="WebCam"
        footer={
          <div className="mt-4">
            <AddButtonSection buttons={buttons} />
          </div>
        }
        visible={visible}
        className="webcam-dialog-container"
        onHide={() => setVisible(false)}
      >
        <div className="flex">
          <div className="pr-4">
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              minScreenshotWidth={imgWidth ? imgWidth : 200}
              minScreenshotHeight={imgHeight ? imgHeight : 150}
            />
          </div>
          <div className="flex flex-column">
            {imgSrc && (
              <>
                <img src={imgSrc} alt="img" width="200" height="150" />
                <div>
                  <AddButtonSection
                    buttons={[
                      {
                        type: "primarySuccessButton",
                        label: "Add Photo",
                        class: "col-12 pr-8 pt-4",
                        onClick: () => {
                          onImageCapture(imgSrc);
                          setVisible(false)
                        },
                      },
                    ]}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Dialog>

      <AddButtonSection
        buttons={[
          {
            type: "primaryButton",
            label: "Capture Photo",
            class: "col-12 pr-8 pl-3",
            icon: "pi pi-camera",
            onClick: () => {
              setVisible(true);
              setImgSrc(null);
            },
          },
        ]}
      />
    </>
  );
};

export default CapturePhoto;
