import React, { useEffect, useState } from "react";
import PrimarySideBar from "../../components/sideBar/primarySideBar";
import Loader from "../../commonComponents/loader";
import InputPrimaryComponents from "../../components/inputPrimaryComponents";
import { commonApi } from "../../utils/apiService";
import {  useForm } from "react-hook-form";
import AddButtonSection from "../../components/AddButtonSection";
import HighlightComponent from "../../components/highlightComponent";
import trash from "../../assets/images/trash.svg";
import { DevTool } from "@hookform/devtools";
import { fetchOptions } from "../../utils/utils";
import _ from "lodash";
import { showToast } from "../../commonComponents/toast";

export default function AddInvoice({ edit, ...props }) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm();

  const [visible, setVisible] = useState(false);
  const [serverLoading, setServerLoading] = useState(null);
  const [state, setState] = useState({});
  const [totalList, setTotalList] = useState([0]);
  const [paymentHistory, setPaymentHistory] = useState([0]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataExist, setDataExist] = useState(false);
  const [searchData, setSearchData] = useState();
  const lookupTypes = [{ type: "Payment_History", setter: setPaymentHistory }];

  const patient = (search = {}, type) => {
    commonApi("/api/invoicePatientSearch", search).then((data) => {
      setState((prev) =>
        type == "name"
          ? {
              ...prev,
              patientName: { items: data?.data?.data },
              patient_common: { items: data?.data?.data },
            }
          : {
              ...prev,
              patientId: { items: data?.data?.data },
              patient_common: { items: data?.data?.data },
            }
      );
    });
  };

  const onsubmit = (data) => {
    if (
      !state?.selectedPatient?.patient_name ||
      !state?.selectedPatient?.patient_code
    ) {
      return setError(true);
    }
    const invoicedata = {
      patient_id: state?.selectedPatient?.patient_id,
      collectable_amount: totalList.map((item) => ({
        payment_category: watch(`category-${item}`),
        description: watch(`description-${item}`),
        amount: watch(`amount-${item}`),
      })),
    };
    if (dataExist)
      invoicedata.collectable_payment_description_id = props?.invoice_id;
    if (serverLoading) return;
    setServerLoading(true);
    commonApi(
      dataExist ? "api/editInvoice" : "/api/addinvoice",
      invoicedata
    ).then((data) => {
      if (
        data?.data?.status === "created" ||
        data?.data?.status === "updated"
      ) {
        reset();
        props?.init?.();
        setDataExist(false);
        showToast(
          "success",
          dataExist
            ? "Invoice updated Successfully"
            : "Invoice Added Successfully"
        );
        props?.setVisible?.(false);
        setServerLoading(false);
      } else {
        showToast("error", "Something went wrong");
        setServerLoading(false);
      }
    });
  };

  const patientData = async (patientId) => {
    if (patientId) {
      await commonApi("/api/searchpatientdetails", {
        patient_id: patientId,
      }).then((patientData) => {
        if (patientData?.data?.patient) {
          setState((prev) => ({
            ...prev,
            selectedPatient: patientData?.data?.patient,
          }));
          setLoading(false);
        }
      });
    }
  };

  const init = () => {
    commonApi("/api/getInvoiceById", {
      collectable_payment_description_id: props?.invoice_id,
    }).then(async (data) => {
      await patientData(data?.data?.invoice?.patient_id);
      if (data?.data?.invoice && Object.keys(data?.data?.invoice).length > 0) {
        setDataExist(true);
        const { payment_categories, ...rest } = data?.data?.invoice;
        setValue("total_invoice_amount", rest?.total_collectable_amount);
        setValue("total_paid_amount", rest?.total_paid_amount);
        setValue("total_balance_amount", rest?.total_balance_amount);
        if (payment_categories?.length > 0) {
          setTotalList([...Array(payment_categories.length).keys()]);
          [...Array(payment_categories.length).keys()]?.map?.((item) => {
            setValue(
              `category-${item}`,
              payment_categories[item]?.payment_category
            );
            setValue(
              `description-${item}`,
              payment_categories[item]?.description
            );
            setValue(
              `amount-${item}`,
              payment_categories[item]?.collectable_amount
            );
          });
        }
      }
    });
  };

  const buttons = [
    {
      type: "primarySuccessButton",
      label: "Submit",
      class: "col-2",
      onClick: handleSubmit(onsubmit),
    },
    {
      type: "primaryCancelButton",
      label: "Cancel",
      class: "col-2",
      onClick: () => {
        props?.setVisible?.(false);
      },
    },
  ];

  const patientCommonSearch = ({ query }) => {
    setSearchData(query);
  };

  useEffect(() => {
    let handler;
    handler = setTimeout(() => {
      if (searchData) {
        patient({ patient_name: searchData, patient_code: "" });
      }
    }, 600);
    return () => clearTimeout(handler);
  }, [searchData]);
  // const patientNameSearch = ({ query }) => {
  //   patient({ patient_name: query }, "name");
  // };

  // const patientIdSearch = ({ query }) => {
  //   patient({ patient_code: query });
  // };

  useEffect(() => {
    if (props?.patientId) {
      setLoading(true);
      patientData(props?.patientId);
    }
  }, [props?.patientId]);

  useEffect(() => {
    if (edit) {
      setLoading(true);
      init();
    }
  }, [edit]);

  useEffect(() => {
    setValue("invoice_date", new Date());
    lookupTypes.forEach(({ type, setter }) => {
      fetchOptions(type, setter);
    });
  }, []);

  useEffect(() => {
    setVisible(props?.visible);
  }, [props?.visible]);

  useEffect(
    () => {
      let amount = 0;
      setValue("total_paid_amount", amount);
      totalList.forEach((item) => {
        if (typeof watch(`amount-${item}`) == "number")
          amount += watch(`amount-${item}`);
      });
      setValue("total_invoice_amount", amount);
      setValue(
        "total_balance_amount",
        watch("total_paid_amount")
          ? amount - watch("total_paid_amount")
          : amount
      );
    },
    totalList.map((item) => watch(`amount-${item}`))
  );
  return (
    <React.Fragment>
      <PrimarySideBar
        visible={visible}
        onHide={() => props?.setVisible?.(false)}
        header={
          <div className="sideBar-header">
            {edit ? "Edit Invoice" : "Add Invoice"}
          </div>
        }
        className={"w-40rem"}
      >
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div>
              {edit || props?.patientId ? (
                ""
              ) : (
                <div className="">
                  <InputPrimaryComponents
                    inter
                    type="autocomplete"
                    label="Patient Search"
                    field="concatData"
                    placeholder="Type to search"
                    required
                    searchIcon
                    disabled={props?.patientId}
                    items={state?.patient_common?.items}
                    value={[state?.patient_common?.value || ""]}
                    error={
                      !state?.selectedPatient?.patient_common &&
                      error &&
                      "patient is required"
                    }
                    search={patientCommonSearch}
                    onChange={(e) => {
                      if (typeof e == "object") {
                        setState((prev) => ({
                          ...prev,
                          patient_common: {
                            ...prev?.patient_common,
                            value: e?.concatData,
                          },
                          selectedPatient: e,
                        }));
                      } else {
                        setState((prev) => ({
                          ...prev,
                          patient_common: {
                            ...prev?.patient_common,
                            value: e,
                          },
                        }));
                      }
                    }}
                  />
                </div>
              )}
            </div>
            <div className="grid">
              <div className="col-6">
                <InputPrimaryComponents
                  inter
                  type="autocomplete"
                  label="Patient Name"
                  field="patient_name"
                  placeholder="Type here"
                  disabled={props?.patientId || true}
                  items={state?.patientName?.items}
                  value={[state?.selectedPatient?.patient_name || ""]}
                  // error={
                  //   !state?.selectedPatient?.patient_name &&
                  //   error &&
                  //   "patient name is required"
                  // }
                  // search={patientNameSearch}
                  // onChange={(e) => {
                  //   if (typeof e == "object") {
                  //     setState((prev) => ({
                  //       ...prev,
                  //       patientName: {
                  //         ...prev?.patientName,
                  //         value: e,
                  //       },
                  //       selectedPatient: e,
                  //     }));
                  //   } else {
                  //     setState((prev) => ({
                  //       ...prev,
                  //       selectedPatient: {
                  //         patient_name: e,
                  //       },
                  //     }));
                  //   }
                  // }}
                />
              </div>
              <div className="col-6">
                <InputPrimaryComponents
                  inter
                  type="autocomplete"
                  label="Patient ID"
                  field="patient_code"
                  placeholder="Type here"
                  disabled={props?.patientId || true}
                  items={state?.patientId?.items || []}
                  value={[state?.selectedPatient?.patient_code || ""]}
                  // error={
                  //   !state?.selectedPatient?.patient_code &&
                  //   error &&
                  //   "patient code is required"
                  // }
                  // search={patientIdSearch}
                  // onChange={(e) => {
                  //   if (typeof e == "object") {
                  //     setState((prev) => ({
                  //       ...prev,
                  //       patientId: { ...prev?.patientId, value: e },
                  //       selectedPatient: e,
                  //     }));
                  //   } else {
                  //     setState((prev) => ({
                  //       ...prev,
                  //       selectedPatient: {
                  //         patient_code: e,
                  //       },
                  //     }));
                  //   }
                  // }}
                />
              </div>
            </div>

            <div className="grid">
              <div className="col-6">
                <InputPrimaryComponents
                  inter
                  type="calendar"
                  label="Invoice Date"
                  field="invoice_date"
                  placeholder="Select"
                  name="invoice_date"
                  disabled
                  control={control}
                  error={error}
                  // disabled={props?.patientId}
                  // items={state?.patientId?.items || []}
                  // value={[state?.selectedPatient?.patient_code || ""]}
                  // error={
                  //   !state?.selectedPatient?.patient_code &&
                  //   error &&
                  //   "patient code is required"
                  // }
                  // search={patientIdSearch}
                  // onChange={(e) => {
                  //   if (typeof e == "object") {
                  //     setState((prev) => ({
                  //       ...prev,
                  //       patientId: { ...prev?.patientId, value: e },
                  //       selectedPatient: e,
                  //     }));
                  //   } else {
                  //     setState((prev) => ({
                  //       ...prev,
                  //       selectedPatient: {
                  //         patient_code: e,
                  //       },
                  //     }));
                  //   }
                  // }}
                />
              </div>

              <div className="col-6">
                <InputPrimaryComponents
                  type="currency"
                  label="Total Invoice Amount"
                  name="total_invoice_amount"
                  placeholder="Enter value"
                  disabled
                  control={control}
                />
              </div>
            </div>
            <div className="grid">
              <div className="col-6">
                <InputPrimaryComponents
                  type="currency"
                  label="Total Paid Amount"
                  name="total_paid_amount"
                  placeholder="Enter value"
                  disabled
                  control={control}
                />
              </div>
              <div className="col-6">
                <InputPrimaryComponents
                  type="currency"
                  label="Total Balance Amount"
                  name="total_balance_amount"
                  disabled
                  placeholder="Enter value"
                  control={control}
                />
              </div>
            </div>
            <div>
              <HighlightComponent>
                <div className="p-3">
                  <div className="primary-standard-heading">
                    Category Amounts
                  </div>
                  <div>
                    {totalList.map((item, ind) => (
                      <div className="grid pt-3" key={item}>
                        <div className="col-4 w-32">
                          <InputPrimaryComponents
                            type="dropdown"
                            label="Category"
                            errors={errors}
                            rules={{ required: "Category is required" }}
                            name={`category-${item}`}
                            control={control}
                            placeholder="Select"
                            options={paymentHistory || []}
                            inputParentClass={"div_pb-0"}
                          />
                        </div>
                        <div className="col-4 w-32">
                          <InputPrimaryComponents
                            type="text"
                            label="Description"
                            placeholder="Type here"
                            name={`description-${item}`}
                            control={control}
                            inputParentClass={"div_pb-0"}
                          />
                        </div>
                        <div className="col-4 w-32">
                          <InputPrimaryComponents
                            type="currency"
                            label="Amount"
                            rules={{ required: "Amount is required" }}
                            name={`amount-${item}`}
                            errors={errors}
                            control={control}
                            placeholder="Enter"
                            inputParentClass={"div_pb-0"}
                          />
                        </div>
                        <span className="relative">
                          {totalList?.length === 1 ? (
                            ""
                          ) : (
                            <img
                              className="absolute top-30 cursor-pointer"
                              src={trash}
                              onClick={() => {
                                reset({
                                  [`category-${item}`]: "",
                                  [`description-${item}`]: "",
                                  [`amount-${item}`]: "",
                                });
                                setTotalList((prev) =>
                                  prev?.filter((i) => i !== item)
                                );
                              }}
                              alt="trash"
                            />
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="pt-3">
                    <AddButtonSection
                      buttons={[
                        {
                          type: "primaryButton",
                          icon: "pi pi-plus",
                          label: "Add category",
                          class: "col-4",
                          onClick: () => {
                            setTotalList((prev) => [...prev, _.last(prev) + 1]);
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
              </HighlightComponent>
            </div>

            <div className="pt-3">
              <AddButtonSection disabled={serverLoading} buttons={buttons} />
            </div>
          </React.Fragment>
        )}
      </PrimarySideBar>
      <DevTool control={control} />
    </React.Fragment>
  );
}
