import React, { useState } from "react";
import { useNavigate  } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import "./Login.scss";
import loginImage from "../../assets/images/login-image.png";
import logo from "../../assets/images/logo.svg";
import leftIcon from "../../assets/images/chevron-left.svg";
import { Checkbox } from "primereact/checkbox";
import { Button } from 'primereact/button';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    navigate('/home')
    // Perform login logic here
    // ...

    // If login successful, navigate to home page
    // if (/* login successful */) {
    //   navigate('/'); // Assuming '/' is your home page route
    // }
  };
  return (
    <div className="Login__main-container">
      <div className="Login__left-section">
        <div className="Login__image-sec">
          <img src={loginImage} alt="Login Image" />
        </div>
      </div>
      <div className="Login__right-section">
        <div className="Login__right-log-sec">
          <img src={logo} alt="" />
          <h1>Rotary Club</h1>
        </div>
        <div className="Login__right-form-sec">
          <div className="Login__forgot-pwd-sec">
            <div className="Login__right-header-sec">
              <p className="heading">Forgot Password</p>
              <p className="sub-heading">Enter your email and we'll send you instructions to reset your password</p>
            </div>
            <form>
              <div className="Login__form-container mt-4">
                <div className="Login__form-control">
                  <label htmlFor="email">Email</label>
                  <InputText id="email" aria-describedby="username-help" />
                </div>
                <div className="Login__login-btn">
                  <Button label="Send Reset Link" />
                </div>
                <div className="Login__contact-admin-sec back-to-login">
                  <span className="btn-left-icon"><img src={leftIcon} alt="" /></span>
                  <Button link label="Back to login" onClick={()=>navigate("/")}/>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
